export const titleCelulasMadreMobile = "¿QUÉ SON LAS CÉLULAS MADRE?"
export const titleCelulasMadre = "CÉLULAS MADRE"
export const textCelulasMadreOne = "Las células madre o stem cells son un tipo de células muy particulares ya que son indiferenciadas. Al entrar en contacto con otra célula pueden copiarla y luego multiplicarla. La acción de las células madre permite que muchas partes de nuestro cuerpo como la sangre, la piel y los huesos se regeneren en forma permanente."
export const celulasHematopoyeticasTitle =  "Células Hematopoyéticas"
export const celulasHematopoyeticasText = [
    {
        "text": "Sirven para generar componentes de la sangre como ",
        "style": "normal"
    },
    {
        "text": "plaquetas, glóbulos rojos ",
        "style": "bold"
    },
    {
        "text": "y para trasplante de ",
        "style": "normal"
    },
    {
        "text": "médula ósea",
        "style": "bold"
    },
    {
        "text": ".",
        "style": "normal"
    }
]
export const celulasMesenquimalesTitle = "Células mesenquimales"
export const celulasMesenquimalesText = [
    {
        "text": "Regeneran ",
        "style": "normal"
    },
    {
        "text": "músculo, pelo, hueso, piel, cartílago y ligamento ",
        "style": "bold"
    },
    {
        "text": "y ",
        "style": "normal"
    },
    {
        "text": "tejido adiposo",
        "style": "bold"
    },
    {
        "text": ".",
        "style": "normal"
    }
]
export const obtienen ={
    title : "¿CÓMO SE OBTIENEN?",
    text: "Se extraen del cordón umbilical y el parto es el único momento en que pueden obtenerse mediante un procedimiento muy sencillo, indoloro e inocuo, que no genera ningún tipo de riesgo."
}