import React,{useState} from 'react'
import { MdOutlineKeyboardArrowDown,MdOutlineKeyboardArrowUp } from "react-icons/md";
import './serviciosMobile.css'

const ServiciosMobile = ({data,title,color}) => {
    const [showPlan,setShowPlan] =  useState(false);
    
    const handlePlan = () =>{
        setShowPlan(!showPlan);
    }

  
    return (
        <div className = "containerServicerMobile">
            <div className = "containerTitleRow" onClick = {()=>{handlePlan()}} style ={{background : color}}>
                <span className = "titlePlan">{title}</span>
                <MdOutlineKeyboardArrowUp className =  {!showPlan ? `rowDown showPlan` : `rowDown hidePlan`} src = "img/flecha-arriba-mdpi.png" alt = "Flecha hacia arriba" />
                <MdOutlineKeyboardArrowDown className =  {!showPlan ? `rowDown hidePlan`  : `rowDown showPlan`} src = "img/Componentef.png" alt = "Flecha hacia abajo" />
            </div>
            <div className = {showPlan ? ` containerDetallesPlan showPlan` : ` containerDetallesPlan hidePlan`} >
                {data.map(plan=>{
                    return(
                        <div  className =  {showPlan ? ` detallesPlan showPlan` : ` detallesPlan hidePlan`} key = {plan.id}>
                            <img className =  {showPlan ? ` iconDetallePlan showPlan` : ` iconDetallePlan hidePlan`}  src = {plan.icon} alt = "icono"/>
                            <p className = {showPlan ? ` paragraphDetallePlan showPlan` : ` paragraphDetallePlan hidePlan`} >{plan.text}</p>

                        </div>
                    )
                })}
            </div>
            
        </div>
    )
}

export default ServiciosMobile
