import{
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_LOADING,
    AUTH_LOGIN_RESET
} from '../types'

import { postInfo } from '../utils/backendRequest'
import {messageAlert, errorAlert} from "../utils/alerts/alerts";

export function  authLoginActions (data){
    return async (dispatch)=>{
        dispatch(authLoginLoading())
        postInfo(`${data.uri}`, data,
        (body)=>{
            if( data.uri == '/advice' ){
                let mensaje = body.message ? body.message : body.mensaje;
                messageAlert('info','Aviso',mensaje);
                dispatch(authLoginSuccess(body.mensaje));
            }else{
                if(body.status == 500){
                    errorAlert('¡Ups, algo salio mal!','\'Estamos trabajando para resolver este inconveniente cuanto antes. Por favor recargue la página e intente nuevamente.\'');
                    dispatch(authLoginReset())
                }else{
                    dispatch(authLoginSuccess(body.linkWSP));
                    window.open(body.linkWSP,'_blank');
                    dispatch(authLoginReset())
                }

            }
        },
        (err)=>{
            err ? 
            dispatch(authLoginError(err)) : 
            dispatch(authLoginError('Estamos trabajando para resolver este inconveniente cuanto antes. Por favor recargue la página e intente nuevamente.'));

        })
    }
}

const authLoginLoading =()=>({
    type: AUTH_LOGIN_LOADING,
    payload:true
})

const authLoginSuccess =(data)=>({
    type: AUTH_LOGIN_SUCCESS,
    payload:data
})

const authLoginError=(error)=>({
    type:AUTH_LOGIN_ERROR,
    payload:error
})

export const authLoginReset =()=>({
    type:AUTH_LOGIN_RESET, 
})