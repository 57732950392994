import React, { useState, useEffect } from "react";
//COMPONENTS
import ContractBox from "./components/ContractBox";
import Footer from "./components/Footer";
import Navbar from "../Navbar/Navbar";
import Swal from "sweetalert2";
import { postInfo } from "../../utils/backendRequest";
//REDUX
import { removeInstance } from "../../reducers/userInstanceReducer";
import { resetContract } from "../../reducers/debtReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptTermsConditionsAction,
  verifyTermsConditionsAction
} from "../../actions/termsConditionsActions";
import Loading from "../../utils/Loading";
import { getGlobal } from "../../config/global";

export default function Contratos(props) {
  const [contrato, setContrato] = useState([]);
  const dispatch = useDispatch();

  const [values, setValues] = React.useState({
    showPassword: false,
    show: false,
    isOpen: false,
    isOpenLoading: false,
    messageModal: "",
    cardType: undefined,
    filterErrors: {},
  });

  const verifyTermsCondition = (id_user) => dispatch(verifyTermsConditionsAction(id_user));

  const { id_user } = useSelector((state) => state.userInstance);
  const text = useSelector(
    (state) =>
      state.termsConditions.termsConditions &&
      state.termsConditions.termsConditions
  );
  const idTerminos = useSelector(
    (state) =>
      state.termsConditions.termsConditions && state.termsConditions.idTerminos
  );


  //const { docu_tipo, docu_nro } = useSelector((state) => state.userInstance);

  const docu_tipo = getGlobal("tipoDocumento");
  const docu_nro = getGlobal("numDocumento");

  //Loading
  const handleOpenLoading = () => {
    setValues({ ...values, isOpenLoading: true });
  };

  const handleCloseLoading = () => {
    setValues({ ...values, isOpenLoading: false });
  };


  useEffect(() => {
    dispatch(resetContract());
    verifyTermsCondition(id_user);
    let stateOfTermsConditions = window.sessionStorage.getItem("stateOfTermsConditions");
    if(stateOfTermsConditions === 'false'){
      stateOfTermsConditions = false;
    }
    if (stateOfTermsConditions === false && text) {
      Swal.fire({
        title: "Terminos y condiciones Cells",
        html: `<div style="max-height: 50vh;">${text}</div>`,
        icon: "info",
        confirmButtonText: "Aceptar",
        showDenyButton: true,
        denyButtonText: "Rechazar",
        width: "550px",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: "max-height: 50vh;",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          //make dispatch to the action which accept terms & conditions and close de modal

          handleOpenLoading();
          postInfo(
            "/contract",
            {
              tipoDoc: docu_tipo,
              nroDoc: docu_nro,
            },
            (body) => {
              handleCloseLoading();
              setContrato(body.cliente);
              dispatch(acceptTermsConditionsAction(idTerminos, id_user));
            },
            (err) => {
              handleCloseLoading();
              Swal.fire({
                icon: "error",
                title: "Ups...",
                text: "Hubo un inconveniente en la consulta de contratos. Le rogamos que reintente a la brevedad.",
                willClose: () => {
                  props.history.push("/");
                },
              });
            }
          );
        } else if (result.isDenied) {
          Swal.fire("Los cambios no fueron guardados", "", "info");
          dispatch(removeInstance());
          props.history.push("/");
        }
      });
    }else{
      handleOpenLoading();
      postInfo(
          "/contract",
          {
            tipoDoc: docu_tipo,
            nroDoc: docu_nro,
          },
          (body) => {
            handleCloseLoading();
            setContrato(body.cliente);
          },
          (err) => {
            handleCloseLoading();
            Swal.fire({
              icon: "error",
              title: "Ups...",
              text: "Hubo un inconveniente en la consulta de contratos. Le rogamos que reintente a la brevedad.",
              willClose: () => {
                props.history.push("/");
              },
            });
          }
      );
    }
  }, [text]);

  const goPagos = () => {
    props.history.push("/pagos");
  };

  return (
    <>
      {/* <NavigationLogin /> */}
      <Navbar />
      <Loading
        isOpenLoading={values.isOpenLoading}
        handleCloseLoading={handleCloseLoading}
      ></Loading>
      <ContractBox contratos={contrato} goPagos={goPagos} />
      <Footer />
    </>
  );
}
