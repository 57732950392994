import{
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_LOADING,
    AUTH_LOGIN_RESET
} from '../types'

const initialState ={
    loading: false,
    error:null,
    data:null,
}

export default function authLoginReducer(state = initialState,action){
    switch(action.type){
        case  AUTH_LOGIN_LOADING:
          return{
            ...state,
            error:null,
            data:null,
            loading: action.payload
        }
        case  AUTH_LOGIN_SUCCESS:
            return{
                ...state,
                loading:false,
                data:action.payload
            }
        case   AUTH_LOGIN_ERROR:
            return{
                ...state,
                loading:false,
                data: null,
                error: action.payload
            } 
            case AUTH_LOGIN_RESET:
                return{
                    ...state,
                    loading:false,
                    data:null,
                    error:null
                }     
        default:
            return state
    }    
}