import React from 'react';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { BsBank } from 'react-icons/bs';
import { BiCreditCard } from 'react-icons/bi';

import './formasDePagoComponent.css'

export const FormasDePagoComponent = ({ data }) => {

    return (
        <>
            <h1 className="parrafo" style={{marginTop: '83px'}}>
                { data.formasDePago.titulo }
            </h1>
            <p className="margen-descripcion">
                { data.formasDePago.descripcion }
            </p>
            <ul className="list-group list-group-flush margen itemList">

                <li><BiCreditCard/> Tarjetas de crédito Visa y Mastercard </li>
                <li><FaRegMoneyBillAlt/> Efectivo </li>
                <li><BsBank/> Depósito / Transferencia bancaria </li>

            </ul>
        </>

    )
}

