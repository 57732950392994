/* eslint no-underscore-dangle: 0 */
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import datepickerStyles from "react-datepicker/dist/react-datepicker.min.css";
import es from "date-fns/locale/es";

registerLocale("es", es);

const SmgDatePicker = ({ ...otherProps }) => (
  <React.Fragment>
    <style>
      {datepickerStyles.style}
      {`
        .react-datepicker > *  {
          font-size: 1rem;
          zoom: 1.2;
        }
        .react-datepicker-wrapper {
          display: block;
          padding: 0;
          border: 0;
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__day--keyboard-selected:hover,
        .react-datepicker__month-text--keyboard-selected:hover {
          background-color: #E4002B;
          text-align:center
        }
        .react-datepicker__month-text.react-datepicker__month--selected:hover{
          background-color: #E4002B;
        }
        .react-datepicker__month-text.react-datepicker__month--selected{
          background-color: #E4002B;
        }
        @media only screen and (max-width: 767px) {
          .react-datepicker__triangle {
            left: 115px;
          }
        }
      `}
    </style>
    <DatePicker locale="es" {...otherProps} />
  </React.Fragment>
);

export default SmgDatePicker;
