const initialState = {
    contract: "",
    debt: 0
}

const RESET_CONTRACT = 'RESET_CONTRACT';
const SET_CONTRACT = 'SET_CONTRACT';
const SET_DEBT = 'SET_DEBT';


export const resetContract = () => {
    return {
        type: RESET_CONTRACT
    }
}

export const setContract = (value) => {
    return {
        type: SET_CONTRACT,
        contract: value
    }
}

export const setDebt = (value) => {
    return {
        type: SET_DEBT,
        debt: value
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTRACT:
            return {
                ...state,
                contract: action.contract
            }
        case SET_DEBT:
            return {
                ...state,
                debt: action.debt
            }
        case RESET_CONTRACT:
            return {
                contract: "",
                debt: 0,
            }
        default:
            return state;
    }
}

export default reducer