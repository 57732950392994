import {
  makeStyles,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

let theme = createTheme({});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  "& .MuiButton-root": {
    textTransform: "lowercase",
    "& > :first-letter": {
      textTransform: "capitalize",
    },
  },
  sendButton: {
    // textTransform: "lowercase",
    // "& > :first-letter": {
    //   textTransform: "capitalize",
    // },
    backgroundColor: "#fafafa",
    height: "40px",
    color: "#00748b",
    fontSize: "13px",
    borderColor: "#00748b",
    borderRadius: "25px",
    //textTransform: "capitalize",
    fontFamily: 'Red Hat Display',
    letterSpacing: "0px",
  },
}));

export { theme, useStyles };
