import React from 'react'
import {useField} from 'formik';
import './styles.css';

const Select = ({...props}) => {
    const [field,meta] =useField(props)
    return (
        <div className = "containerInput">
        <select className = "inputSelect input" {...field} {...props}/>
            {meta.touched && meta.error ? <p className = "errors">{meta.error}</p>:null}
        </div>
    )
}

export default Select
