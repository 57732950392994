export const servicesItems=[
    { text:"KIT de recoleccion",
    stateItem:true,stateItemTwo:true,stateItemThree:true
  },
    { text:"Recoleccción de células madre",
      stateItem:true,stateItemTwo:true,stateItemThree:true
    },
    { text:"Recolección del tejido del cordón",
      stateItem:false,stateItemTwo:true,stateItemThree:true
    },
    { text:"Traslado de la muestra*",
      stateItem:true,stateItemTwo:true,stateItemThree:true
    }, 
    { text:"Procesamiento y análisis",
      stateItem:true,stateItemTwo:true,stateItemThree:true
    }, 
    { text:"Expansión de células madre del tejido",
      stateItem:false,stateItemTwo:true,stateItemThree:true    
    }, 
    { text:"Criopreservación de las muestras",
      stateItem:true,stateItemTwo:true,stateItemThree:true
    }, 
    { 
      text:"Criopreservación de Backup de las muestras",
      stateItem:true,stateItemTwo:true,stateItemThree:true
    },
    {
      text:"Primer año de almacenamiento bonificado",
      stateItem:true,stateItemTwo:true,stateItemThree:true
    },
    {
      text:"Contratacion por segundo hijo/a",
      stateItem:false,stateItemTwo:false,stateItemThree:true
    },
    { text:"Bonificación anual por fallecimiento de padres",
      stateItem:true,stateItemTwo:true,stateItemThree:true
  }
  ];
  
  
export const investigation=[
    {text:"Diabetes"}, 
    {text:"Parkinson"}, 
    {text:"Parálisis cerebral"}, 
    {text:"Infarto del miocardio"}, 
    {text:"Esclerosis múltiple"}, 
    {text:"Quemaduras de piel"}, 
    {text:"Lesiones de córnea"}, 
    {text:"Regeneración de cartílagos"},
    {text:"Enfermedad vascular periférica"}, 
    {text:"Lesión de médula espinal"}, 
    {text:"Colagenopatía"}
  ]
  
export const patologies=[
  {text:"Anemia aplásica hereditaria"}, 
  {text:"Leucemias"},
  {text:"Linfomas"},
  {text:"Osteoporosis"}, 
  {text:"Mieloma múltiple"}, 
  {text:"Neuroblastoma"},
  {text:"Enfermedades inmunes"}, 
  {text:"Trastornos mieloproliferativos"}, 
  {text:"Trastornos de almacenamiento de lípidos"}
  ]


export const cyclicItems=[
{       name:"RECOLECCIÓN",
        text1:"Con autorización del obstetra, un profesional interviene el cordón umbilical para obtener la",
        text2:"sangre de su interior y/o una sección del mismo.",
        img:"img/1.png"
    },{
        name:"PROCESAMIENTO",
        text1:"Se analizan las muestras para saber si son aptas para ser procesadas y se separan los",
        text2:"componentes de la sangre hasta llegar a las células madre.",
        img:"img/2.png"
   },{
        name:"EXPANSIÓN",
        text1:"Las células madre mesenquimales son expandidas mediante cultivo celular hasta obtener",
        text2:"un recuento igual o mayor a 3 x 10^6.",
        img:"img/3.png"
   },{
        name:"CRIOPRESERVACIÓN",
        text1:"Las muestras se someten a un descenso controlado y programado de temperatura, luego se",
        text2:"las deposita en los termos de criopreservación y se sumergen en nitrógeno líquido.",
        img:"img/4.png"
}
]

export const primerDiaTitle =[
  { 
    key:1,
    title:"CUIDALO DESDE"},
  { 
    key:2,
    title:" EL PRIMER DÍA"}
] 
export const primerDiaParrafo = [
  {
    key:1,
    text: "Brindale a tu hijo la " },
  {
    key:2,
    text:"oportunidad de guardar "},
  {
    key:3,
    text: "sus células madre."}
]
export const primerDiaParrafoMobile =[
  {
    key:1,
    text:"Brindale a tu hijo la oportunidad "
  },
  {
    key:2,
    text:"de guardar sus células madre."
  }
]

export const whatsapp = {
  src: 'img/btn_whatsapp.png'
}