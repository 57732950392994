export const planesServicios = [
{   
    id: 1,
    title: "ESSENTIAL LINE",
    color: "#6E7EB9",
    plan: [
        {
            id:1,
            icon: "img/check.png",
            text: "KIT de Recolección"
        },
        {
            id:2,
            icon: "img/check.png",
            text: "Recolección de células madre"
        },
        {
            id:3,
            icon: "img/error.png",
            text: "Recolección del tejido del córdon"
        },
        {
            id:4,
            icon: "img/check.png",
            text: "Traslado de la muestra*"
        },
        {
            id:5,
            icon: "img/check.png",
            text: "Procesamiento y análisis"
        },
        {
            id:6,
            icon: "img/error.png",
            text:"Expansión de células madre del tejido"
        },
        {
            id:7,
            icon: "img/check.png",
            text:"Criopreservación de Backup de las muestas"
        },
        {
            id:8,
            icon: "img/check.png",
            text: "Primer año de almacenamiento bonificado"
        },
        {
            id:9,
            icon: "img/error.png",
            text: "Contratación por segundo hijo/a***"
        },
        {
            id:10,
            icon: "img/check.png",
            text: "Bonificación anual por fallecimiendo de padres"
        }
      
    ]

},
{
    id:2,
    title : "CYCLIC LINE ",
    color:"#E86F70",
    plan : [
    {
        id:1,
        icon: "img/check.png",
        text: "KIT de Recolección"
    },
    {
        id:2,
        icon: "img/check.png",
        text: "Recolección de células madre"
    },
    {
        id:3,
        icon: "img/check.png",
        text: "Recolección del tejido del córdon"
    },
    {
        id:4,
        icon: "img/check.png",
        text: "Traslado de la muestra*"
    },
    {
        id:5,
        icon: "img/check.png",
        text: "Procesamiento y análisis"
    },
    {
        id:6,
        icon: "img/check.png",
        text:"Expansión de células madre del tejido"
    },
    {
        id:7,
        icon: "img/check.png",
        text:"Criopreservación de Backup de las muestas"
    },
    {
        id:8,
        icon: "img/check.png",
        text: "Primer año de almacenamiento bonificado"
    },
    {
        id:9,
        icon: "img/error.png",
        text: "Contratación por segundo hijo/a***"
    },
    {
        id:10,
        icon: "img/check.png",
        text: "Bonificación anual por fallecimiendo de padres"
    }
    
]
},{
    id:3,
    title : "PROMO HERMANOS 50% OFF **",
    color:"#00758D",
    plan: [
        {
            id:1,
            icon: "img/check.png",
            text: "KIT de Recolección"
        },
        {
            id:2,
            icon: "img/check.png",
            text: "Recolección de células madre"
        },
        {
            id:3,
            icon: "img/check.png",
            text: "Recolección del tejido del córdon"
        },
        {
            id:4,
            icon: "img/check.png",
            text: "Traslado de la muestra*"
        },
        {
            id:5,
            icon: "img/check.png",
            text: "Procesamiento y análisis"
        },
        {
            id:6,
            icon: "img/check.png",
            text:"Expansión de células madre del tejido"
        },
        {
            id:7,
            icon: "img/check.png",
            text:"Criopreservación de Backup de las muestas"
        },
        {
            id:8,
            icon: "img/check.png",
            text: "Primer año de almacenamiento bonificado"
        },
        {
            id:9,
            icon: "img/check.png",
            text: "Contratación por segundo hijo/a***"
        },
        {
            id:10,
            icon: "img/check.png",
            text: "Bonificación anual por fallecimiendo de padres"
        }
    ]
}
]