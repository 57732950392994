import React from 'react'
import {useField} from 'formik';
import './styles.css';


const TextArea = ({widthInput,heightInput,...props}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <textarea className="text-area input" {...field} {...props} style = {{width :`${widthInput}px`, height:`${heightInput}px`}} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
  };

  export default TextArea