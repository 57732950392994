import {
  makeStyles,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

let theme = createTheme({});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  grid: {
    height: "30vh",
    textAlign: "center",
  },
}));

export { theme, useStyles };
