import Swal from 'sweetalert2';
import './alerts.css'

export const messageAlert = (icon,title, text) =>
  Swal.fire({
    title,
    text,
    icon,
    confirmButtonText: 'Aceptar',
  });

  
export const errorAlert = (title,text) =>
Swal.fire({
  title,
  text,
  icon: 'info',
  confirmButtonText: 'Aceptar',
});


export const popUp =  (title,text)=>{
  Swal.fire({
    title,
    text,
    position: 'center',
    icon: 'success',
    showConfirmButton: false,
    confirmButtonText: 'Aceptar',
  })
}

