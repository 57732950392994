import React from "react";
import "./style.css";
import zxcvbn from "zxcvbn";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";
import lightGreen from "@material-ui/core/colors/lightGreen";

import { useField } from "formik";
import { useFormikContext } from "formik";

import {
  withStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { grey } from "@material-ui/core/colors";

const styles = (theme) => ({
  // margin: {
  //   margin: theme.spacing.unit,
  // },
  flexBasis: 500,
  root: {
    marginTop: 16,
    width: "100%",
    maxWidth: "100%",
    //backgroundColor: theme.palette.background.paper,
    //position: "relative",
    //overflow: "auto",
    //maxHeight: 80,
    borderColor: "orange",
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const scores = [
  {
    title: "Débil",
    icon: "fas fa-frown-open",
    color: grey,
    index: 0,
  },
  {
    title: "Media",
    icon: "fas fa-meh",
    color: red,
    index: 1,
  },
  {
    title: "Okay",
    icon: "fas fa-meh-rolling-eyes",
    color: yellow,
    index: 2,
  },
  {
    title: "Buena",
    icon: "fas fa-smile-wink",
    color: lightGreen,
    index: 3,
  },
  {
    title: "Muy Buena",
    icon: "fas fa-grin-stars",
    color: green,
    index: 4,
  },
];

function InputPasswordField(props) {
  const { values: formValues } = useFormikContext();

  const hasUpperCase = /[A-Z]/.test(formValues.clave);
  const hasLowerCase = /[a-z]/.test(formValues.clave);
  const hasNumber = /[0-9]/.test(formValues.clave);

  // const { score } =
  //   hasUpperCase && hasLowerCase && hasNumber
  //     ? { score: 0 }
  //     : zxcvbn(formValues.clave);

  const { score } = zxcvbn(formValues.clave);
  const scoreItem = scores[score];
  const charLength = formValues.clave.length;

  const { errorText, ...rest } = props;
  const [field] = useField(props);

  const theme = createTheme({
    palette: {
      primary: score.color,
    },
    typography: { useNextVariants: true },
  });

  const [show, setShow] = React.useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const levelBarCss = (level) => ({
    height: "8px",
    width: level > 0 ? `${(100 / 4) * level}%` : "100%",
    // marginTop: 16,
    transition: "width 0.5s ease",
    backgroundColor: ["grey", "red", "yellow", "lightGreen", "green"][level],
    borderRadius: 0,
  });

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        {...field}
        {...rest}
        className={"niceinput-amplify"}
        margin="normal"
        variant="outlined"
        // className={classes.margin}
        //onChange={onChange}
        type={show ? "text" : "password"}
        InputProps={{
          classes: {
            root: { borderColor: "orange !important" },
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShow(!show)}
                onMouseDown={handleMouseDownPassword}
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography
        style={{ color: charLength > 0 ? scoreItem.color[500] : "#797979" }}
      >
        La contraseña debe contener al menos 8 caractéres alfanuméricos.
      </Typography>
      {!(hasUpperCase && hasLowerCase && hasNumber) && (
        <Typography
          style={{
            color: "red",
          }}
        >
          {charLength >= 8 && "No se cumple."}
        </Typography>
      )}

      {
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Typography>Seguridad de la contraseña:</Typography>
          </Grid>
          <Grid item xs={4}>
            <div style={{ width: "100%", backgroundColor: "grey" }}>
              <div style={levelBarCss(scoreItem.index)}></div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{ paddingLeft: "10px", color: scoreItem.color[500] }}
            >
              {charLength > 0 && scoreItem.title}
            </Typography>
          </Grid>
        </Grid>
      }
    </MuiThemeProvider>
  );
}
const Formatted = withStyles(styles)(InputPasswordField);

export default Formatted;
