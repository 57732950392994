import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MediaQuery from "react-responsive";
import Navbar from "../Navbar/Navbar";

import { Collapse } from "@material-ui/core";
import Contact from "../Contact/Contact";

import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { isEqual } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import Loading from "../../utils/Loading";
import getBrowser from "../../utils/getBrowser";
import guid from "../../utils/getGuid";
import { apiManagerRequest as amr } from "../../utils/apiManagerRequest";
import { apiSgiRequest as asgi } from "../../utils/apiManagerRequest";
import {
  LOCALSTORAGE_AUTH_API,
  LOCALSTORAGE_AUTH_CAPTCHA,
} from "../../constants";
//const reCpub = window.REACT_APP_RECAPTCHA_INVISIBLE_PUBLIC_KEY;
//const reCpriv = window.REACT_APP_RECAPTCHA_INVISIBLE_PRIVATE_KEY;

import "../RecuperarPassword/recuperarPassword.css";
import { useForm } from "../../hooks/useForm";
import Modal from "../../utils/Modal";
import { postInfo } from "../../utils/backendRequest";
const axios = require("axios");
const API_KEY = window.REACT_APP_API_KEY;
const USR_LOGIN = window.REACT_APP_USR_LOGIN;
const PASSWORD = window.REACT_APP_PASSWORD;
//const SECRET_KEY = window.REACT_APP_SECRET_KEY;
const SITE_KEY = window.REACT_APP_SITE_KEY;

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ffff",
    zIndex: 1,
    color: "#707070",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid gray",
  },
  active: {
    backgroundColor: "#00748b",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: "white",
    border: "2px solid #00748b",
  },
  completed: {
    backgroundColor: "#00748b",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: "white",
    border: "2px solid #00748b",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiFormLabel-root": {
      fontSize: "13px",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "13px",
    },
    "& .MuiTypography-body1": {
      fontSize: "18px",
      fontFamily: 'Red Hat Display',
      "@media (min-width:768px)": {
        fontSize: "13px",
        fontFamily: 'Red Hat Display',
      },
    },
    "& .MuiTypography-body2": {
      fontSize: "18px",
      "@media (min-width:768px)": {
        fontSize: "13px",
        fontFamily: 'Red Hat Display',
      },
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "#00748b",
      fontFamily:'Red Hat Display',
    },
    "& .MuiDivider-middle": {
      backgroundColor: "00748b",
      border: "1px solid #00748b",
    },
    "& .MuiFormHelperText-root ": {
      color: "red",
    },
    "& .MuiSvgIcon-root": {
      color: "#00758d",
    },
    "& .MuiInputBase-root": {
      color: "#707070",
      fontSize: "14px",
    },

    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336 !important",
    },
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#00758d",
    borderRadius: "25px",
    margin: "0",
    fontSize: "13px",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paperB: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function getSteps() {
  return ["Datos personales", "Confirmación", "Vinculación"];
}

export default function RecuperarPassword({ field, form, ...other }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [modal, setModal] = useState({
    open: false,
    type: 500,
    title: "",
  });

  const [formValues, handleInputChange] = useForm({
    tDocumento: "DU",
    nDocumento: "",
    email: "",
    code: "",
    newPassword: "",
    confPassword: "",
    recaptcha: "",
  });

  const {
    email,
    tDocumento,
    nDocumento,
    code,
    newPassword,
    confPassword,
    recaptcha,
  } = formValues;

  useEffect(() => {}, [
    email,
    tDocumento,
    nDocumento,
    code,
    newPassword,
    confPassword,
    recaptcha,
  ]);

  const [values, setValues] = useState({
    show: false,
    isOpen: false,
    isOpenLoading: false,
    messageModal: "",
    filterErrors: {},
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    values.filterErrors = validate();
    if (Object.keys(values.filterErrors).length === 0) {
      values.filterErrors = validateEspecial();
    }
    setValues({ ...values, setValues });
    if (Object.keys(values.filterErrors).length) return;
    else {
      getTokenApi(formValues);
    }
  };

  //Loading
  const handleOpenLoading = () => {
    setValues({ ...values, isOpenLoading: true });
  };

  const handleCloseLoading = () => {
    setValues({ ...values, isOpenLoading: false });
  };

  const handleOpen = (msj) => {
    setModal({ open: true, type: 401, title: msj });
  };

  const responseCaptcha = (response) => {
    setValues({ ...values, captchaOk: false, captcha: response });
    sessionStorage.setItem(LOCALSTORAGE_AUTH_CAPTCHA, response);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConf = () => {
    setValues({ ...values, showPasswordConf: !values.showPasswordConf });
  };

  const handleMouseDownPasswordConf = (event) => {
    event.preventDefault();
  };
  const validateOnKeyDown = (evt, type = "onlyNumbers") => {
    const regexs = {
      onlyNumbers: /[0-9]/,
      onlyLetters: /[a-zA-ZñÑ]/,
    };

    const { keyCode, which, ctrlKey, key } = evt;
    const charCode = which || keyCode;

    const pressBackSpace = isEqual(charCode, 8);
    const pressTab = isEqual(charCode, 9);
    const pressSpace = isEqual(charCode, 32);
    const pressCtrlAndV = ctrlKey ? ctrlKey && isEqual(charCode, 86) : false;
    const pressKeyInlcudeInRegex = regexs[type].test(key);

    if (
      !pressBackSpace &&
      !pressTab &&
      (isEqual(type, "onlyLetters") ? !pressSpace : true) &&
      !pressCtrlAndV &&
      !pressKeyInlcudeInRegex
    ) {
      return evt.preventDefault();
    }
  };

  // eslint-disable-next-line consistent-return
  const validateOnPaste = (evt, type = "onlyNumbers") => {
    const regexs = {
      onlyNumbers: /^[0-9]+$/,
      onlyLetters: /^[a-zA-ZñÑ]{1,20}(?:\s[a-zA-ZñÑ]{1,20}){0,9}$/,
    };
    const clipBoard = evt.clipboardData.getData("Text");

    if (!regexs[type].test(clipBoard)) {
      return evt.preventDefault();
    }
  };

  const validate = () => {
    const errorsFilter = {};
    if (activeStep === 0) {
      if (!nDocumento) errorsFilter.nDocumento = "Campo Requerido";
      if (!tDocumento || tDocumento === "Campo Requerido")
        errorsFilter.tDocumento = "Campo Requerido";
      if (!email) errorsFilter.email = "Campo Requerido";
    } else if (activeStep === 1) {
      if (!code) errorsFilter.code = "Campo Requerido";
    } else if (activeStep === 2) {
      if (!newPassword) errorsFilter.newPassword = "Campo Requerido";
      if (!confPassword) errorsFilter.confPassword = "Campo Requerido";
      //if (!contrato) errorsFilter.contrato = "Campo Requerido";
    }
    return errorsFilter;
  };

  const validateEspecial = () => {
    const errorsFilter = {};
    if (activeStep === 2) {
      var patternP = new RegExp("([A-Za-z0-9]{8,15}$)");
      if (!patternP.test(newPassword))
        errorsFilter.newPassword = "Contraseña no valida";
      if (newPassword !== confPassword)
        errorsFilter.newPassword = "Contraseñas no coinciden";
      errorsFilter.confPassword = "Contraseñas no coinciden";
    } else if (activeStep === 0) {
      var patternE = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!patternE.test(email)) errorsFilter.email = "Eamil no valido";
    }
    return errorsFilter;
  };

  //Calls api
  const getTokenApi = (values) => {
    const campos = values;
    const ipUser = guid();
    const devicename = getBrowser();
    const auth = sessionStorage.getItem(LOCALSTORAGE_AUTH_CAPTCHA);
    handleOpenLoading();
    postInfo('/recuperacion', 
    {
      recaptchaResponse: auth,
      usrLoginName: USR_LOGIN,
      password: PASSWORD,
    }
    /* amr(
      "cl/api-smg/v0/auth-login",
      {
        method: "POST",
        json: {
          recaptchaResponse: auth,
          apiKey: API_KEY,
          usrLoginName: USR_LOGIN,
          password: PASSWORD,
          device: {
            bloqueado: false,
            recordar: false,
            deviceid: ipUser,
            messagingid: ipUser,
            devicename: devicename,
          },
        },
      } */,
      (res) => {
        if (activeStep === 0) {
          handleSubmitPersonalDataForm(res, campos);
        } else if (activeStep === 1) {
          handleSubmitConfirmCodeForm(res, campos);
        } else if (activeStep === 2) {
          resetPassword(res, campos);
        }
      },
      (error) => {
        handleCloseLoading();
        handleOpen(error.body.data.errMessage);
      }
    );
  };

  const handleSubmitPersonalDataForm = (res, campos) => {
    sessionStorage.setItem(LOCALSTORAGE_AUTH_API, res.token);

    console.log(email, tDocumento, nDocumento);
    postInfo('recuperacion/paso1'
    /* amr(
      "api-smg/V1/cells/usuarios/recuperacion/paso1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            LOCALSTORAGE_AUTH_API
          )}`,
        } */,
        {
          email: email,
          tipoDoc: tDocumento,
          nroDoc: nDocumento,
        }
      ,
      (res) => {
        handleCloseLoading();
        handleNext();
      },
      (error) => {
        handleCloseLoading();
        handleOpen(error.data.errMessage);
      }
    );
  };

  const handleSubmitConfirmCodeForm = (res, campos) => {
    console.log("paso2");
    sessionStorage.setItem(LOCALSTORAGE_AUTH_API, res.token);
    postInfo('/recuperacion/paso2', {email, plainPin: code}
    /* amr(
      "api-smg/V1/cells/usuarios/recuperacion/paso2",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            LOCALSTORAGE_AUTH_API
          )}`,
        },
        json: {
          email: email,
          plainPin: code,
        },
      } */,
      (res) => {
        handleCloseLoading();
        handleNext();
      },
      (error) => {
        handleCloseLoading();
        handleOpen(error.data.errMessage);
      }
    );
  };

  const resetPassword = (res) => {
    console.log(res);
    postInfo('/recuperacion/paso3', {
      email: email,
      tipoDoc: tDocumento,
      nroDoc: nDocumento,
      plainPassword: newPassword,
      plainPin: code,
      contrato: res.contra,
    }
    /* amr(
      "api-smg/V1/cells/usuarios/recuperacion/paso3",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem(
            LOCALSTORAGE_AUTH_API
          )}`,
        },
        json: {
          email: email,
          tipoDoc: tDocumento,
          nroDoc: nDocumento,
          plainPassword: newPassword,
          plainPin: code,
          contrato: res.contra,
        },
      } */,
      (res) => {
        handleCloseLoading();
        handleOpen(res);
        window.location.href = "/login-clientes";
      },
      (error) => {
        handleCloseLoading();
        handleOpen(error.response.data.message);
      }
    );
  };
  /*

  const handleValidateContrato = (res) => {
    sessionStorage.setItem(LOCALSTORAGE_AUTH_API, res.token);

    const AuthStr = "Bearer ".concat(LOCALSTORAGE_AUTH_API);
    axios
      .get(
        `http://sgiqa/ws.clientes/api/clientes?dni=${nDocumento}&docTipo=${tDocumento}`,
        { headers: { Authorization: AuthStr } }
      )
      .then((response) => {
        handleCloseLoading();
        handleSubmitEntailmentForm(response.data.cliente[0]);
      })
      .catch((error) => {
        handleCloseLoading();
        handleOpen(error.response.data.message);
      });
  };
*/
  return (
    <div className={classes.root}>
      <Loading
        isOpenLoading={values.isOpenLoading}
        handleCloseLoading={handleCloseLoading}
      ></Loading>

      <Modal
        open={modal.open}
        maxWidth="lg"
        closeIconButton
        title={modal.title}
        onClose={() => setModal(false)}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      ></Modal>
      <MediaQuery maxDeviceWidth={767}>
        {/* Small devices - Portrait */}
        <Navbar />

        <Collapse in={activeStep === 0}>
          <div id="registrarse">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="titulo-registracion">RECUPERAR CONTRASEÑA</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3 className="datos-personales">Datos personales</h3>
                </div>
                <Divider variant="middle" className="divider" />
              </div>
            </div>
            <div className="margin-divider"></div>

            <div className="container">
              <div className="row">
                <form
                  className="col-12"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={3}>
                      <InputLabel htmlFor="standard-adornment-password">
                        <strong className="b-1-r">Documento</strong>
                      </InputLabel>
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="tDocumento"
                        name="tDocumento"
                        value={tDocumento}
                        onChange={handleInputChange}
                        className={classes.root}
                        error={values.filterErrors.tDocumento}
                        helperText={values.filterErrors.tDocumento}
                      >
                        <MenuItem value="">
                          <em>Seleccionar</em>
                        </MenuItem>
                        <MenuItem value={"DU"}>DU</MenuItem>
                        <MenuItem value={"Pasaporte"}>Pasaporte</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item xs={9}>
                      <InputLabel htmlFor="standard-adornment-password">
                        <strong className="b-1-r">Numero</strong>
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="nDocumento"
                        name="nDocumento"
                        value={nDocumento}
                        onChange={handleInputChange}
                        error={values.filterErrors.nDocumento}
                        helperText={values.filterErrors.nDocumento}
                        onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                        onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                        onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        className={classes.root}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel htmlFor="standard-adornment-password">
                        <strong className="b-1-r">Email</strong>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        variant="outlined"
                        label=""
                        value={email}
                        onChange={handleInputChange}
                        className={classes.root}
                        error={values.filterErrors.email}
                        helperText={values.filterErrors.email}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Grid item xs className={classes.paperB}>
                      <ReCAPTCHA
                        style={{ marginTop: "5%", padding: "4px" }}
                        sitekey={SITE_KEY}
                        onChange={responseCaptcha}
                        message="Requerido"
                      />
                    </Grid>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </Collapse>

        <Collapse in={activeStep === 1}>
          <div id="registrarse">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="titulo-registracion">RECUPERAR CONTRASEÑA</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3 className="datos-personales">Verificar codigo</h3>
                </div>
                <Divider variant="middle" className="divider" />
              </div>
            </div>
            <div className="margin-divider"></div>

            <div className="container">
              <div className="row">
                <form
                  className="col-12"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={9}>
                      <InputLabel htmlFor="standard-adornment-password">
                        <strong className="b-1-r">Codigo</strong>
                      </InputLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        id="code"
                        name="code"
                        value={code}
                        onChange={handleInputChange}
                        error={values.filterErrors.code}
                        helperText={values.filterErrors.code}
                        onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                        onKeyPress={(e) => validateOnKeyDown(e, "onlyNumbers")}
                        onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        className={classes.root}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Grid item xs className={classes.paperB}>
                      <ReCAPTCHA
                        style={{ marginTop: "5%", padding: "4px" }}
                        sitekey={SITE_KEY}
                        onChange={responseCaptcha}
                        message="Requerido"
                      />
                    </Grid>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </Collapse>

        <Collapse in={activeStep === 2}>
          <div id="registrarse">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3 className="titulo-registracion">RECUPERAR CONTRASEÑA</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3 className="datos-personales">Cambiar contraseña</h3>
                </div>
                <Divider variant="middle" className="divider" />
              </div>
            </div>
            <div className="margin-divider"></div>

            <div className="container">
              <div className="row">
                <form
                  className="col-12"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="standard-adornment-password">
                        <strong className="b-1-r">Contraseña</strong>
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type={values.showPassword ? "text" : "password"}
                        id="newPassword"
                        name="newPassword"
                        label=""
                        value={newPassword}
                        onChange={handleInputChange}
                        className={classes.root}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={values.filterErrors.newPassword}
                        helperText={values.filterErrors.newPassword}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="standard-adornment-password">
                        <strong className="b-1-r">Confirmar contraseña</strong>
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type={values.showPasswordConf ? "text" : "password"}
                        id="confPassword"
                        name="confPassword"
                        label=""
                        value={confPassword}
                        onChange={handleInputChange}
                        className={classes.root}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordConf}
                                onMouseDown={handleMouseDownPasswordConf}
                              >
                                {values.showPasswordConf ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={values.filterErrors.confPassword}
                        helperText={values.filterErrors.confPassword}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Grid item xs>
                      <p className="texto-contraseña">
                        La contraseña debe contener al menos 8 caracteres
                        alfanuméricos, un número, una letra mayúscula, una
                        minúscula y no debe contener caracteres especiales.
                      </p>
                    </Grid>
                  </Box>

                  <Box mt={5}>
                    <Grid item xs className={classes.paperB}>
                      <ReCAPTCHA
                        style={{ marginTop: "5%", padding: "4px" }}
                        sitekey={SITE_KEY}
                        onChange={responseCaptcha}
                        message="Requerido"
                      />
                    </Grid>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </Collapse>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <Divider variant="middle" className="divider" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <Grid item xs className={classes.paperB}>
              {activeStep === steps.length ? (
                <div className="btn-end-form">
                  <Typography className={classes.instructions}>
                    Ha Completado el cambio con exito
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    VOLVER AEMPEZAR
                  </Button>
                </div>
              ) : (
                <div className="btn-end-form">
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      color="primary"
                      className={classes.button}
                      size="large"
                      hidden={activeStep === 0}
                      style={{ width: "117px", color: "white" }}
                    >
                      ATRAS
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                      size="large"
                      type="submit"
                    >
                      {activeStep === steps.length - 1
                        ? "TERMINADO"
                        : "SIGUIENTE"}
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minDeviceWidth={768} and maxDeviceWidth={991}>
        {/* Table devices - Portrait */}

        <Navbar />

        <Collapse in={activeStep === 0}>
          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={1}>
                <h2 className="title-central-r">Recuperar contraseña</h2>
              </Box>
            </Box>
          </div>

          <div id="registrarse" style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={1}>
                <h4>Datos personales</h4>
                <br></br>
                <br></br>

                <Divider variant="middle" className="divider" />
              </Box>
            </Box>
          </div>

          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={6}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Container component="main" style={{ width: "570px" }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Documento</strong>
                        </InputLabel>
                        <TextField
                          select
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          id="tDocumento"
                          name="tDocumento"
                          value={tDocumento}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.tDocumento}
                          helperText={values.filterErrors.tDocumento}
                        >
                          <MenuItem value="">
                            <em>Seleccionar</em>
                          </MenuItem>
                          <MenuItem value={"DU"}>DU</MenuItem>
                          <MenuItem value={"Pasaporte"}>Pasaporte</MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Número de documento</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          id="nDocumento"
                          name="nDocumento"
                          value={nDocumento}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.tDocumento}
                          helperText={values.filterErrors.tDocumento}
                          onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                          onKeyPress={(e) =>
                            validateOnKeyDown(e, "onlyNumbers")
                          }
                          onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Email</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          variant="outlined"
                          label=""
                          value={email}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.email}
                          helperText={values.filterErrors.email}
                        />
                      </Grid>
                    </Grid>

                    <Box mt={5}>
                      <Grid item xs className={classes.paperB}>
                        <ReCAPTCHA
                          style={{ marginTop: "5%", padding: "4px" }}
                          sitekey={SITE_KEY}
                          onChange={responseCaptcha}
                        />
                      </Grid>
                    </Box>
                  </Container>
                </form>
              </Box>
            </Box>
          </div>
        </Collapse>

        <Collapse in={activeStep === 1}>
          <div id="registrarse" style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={1}>
                <h4>Confirmar codigo</h4>
                <br></br>
                <br></br>

                <Divider variant="middle" className="divider" />
              </Box>
            </Box>
          </div>

          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={6}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Container component="main" style={{ width: "570px" }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Codigo</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          id="code"
                          name="code"
                          value={code}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.code}
                          helperText={values.filterErrors.code}
                          onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                          onKeyPress={(e) =>
                            validateOnKeyDown(e, "onlyNumbers")
                          }
                          onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        />
                      </Grid>

                      <Box mt={5}>
                        <Grid item xs className={classes.paperB}>
                          <ReCAPTCHA
                            style={{ marginTop: "5%", padding: "4px" }}
                            sitekey={SITE_KEY}
                            onChange={responseCaptcha}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Container>
                </form>
              </Box>
            </Box>
          </div>
        </Collapse>

        <Collapse in={activeStep === 2}>
          <div id="registrarse" style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={1}>
                <h4>Nueva contraseña</h4>
                <br></br>
                <br></br>

                <Divider variant="middle" className="divider" />
              </Box>
            </Box>
          </div>

          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={6}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Container component="main" style={{ width: "570px" }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Contraseña</strong>
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type={values.showPassword ? "text" : "password"}
                          id="newPassword"
                          name="newPassword"
                          label=""
                          value={newPassword}
                          onChange={handleInputChange}
                          className={classes.root}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={values.filterErrors.newPassword}
                          helperText={values.filterErrors.newPassword}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Contraseña</strong>
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type={values.showPassword ? "text" : "password"}
                          id="confPassword"
                          name="confPassword"
                          label=""
                          value={confPassword}
                          onChange={handleInputChange}
                          className={classes.root}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={values.filterErrors.confPassword}
                          helperText={values.filterErrors.confPassword}
                        />
                      </Grid>
                    </Grid>
                    <Box mt={5} style={{ width: "570px" }}>
                      <Grid item xs>
                        <p className="b-1-l">
                          La contraseña debe contener al menos 8 caracteres
                          alfanuméricos, un <br></br>número, una letra
                          mayúscula, una minúscula y no debe contener <br></br>
                          caracteres especiales.
                        </p>
                      </Grid>
                    </Box>

                    <Box mt={5}>
                      <Grid item xs className={classes.paperB}>
                        <ReCAPTCHA
                          style={{ marginTop: "5%", padding: "4px" }}
                          sitekey={SITE_KEY}
                          onChange={responseCaptcha}
                        />
                      </Grid>
                    </Box>
                  </Container>
                </form>
              </Box>
            </Box>
          </div>
        </Collapse>

        <div style={{ width: "100%" }}>
          <Box bgcolor="#ffff">
            <Grid container>
              <Grid item xs></Grid>
              <Grid item xs={8}>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </Box>
        </div>

        <div style={{ width: "100%" }}>
          <Box bgcolor="#ffff" p={4}>
            <Grid item xs className={classes.paperB}>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    Ha Completado la registración
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    VOLVER AEMPEZAR
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      color="primary"
                      className={classes.button}
                      size="large"
                      hidden={activeStep === 0}
                    >
                      ATRAS
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.button}
                      size="large"
                      type="submit"
                    >
                      {activeStep === steps.length - 1
                        ? "TERMINADO"
                        : "SIGUIENTE"}
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
          </Box>
        </div>
        <Contact />
      </MediaQuery>
      <MediaQuery minDeviceWidth={992}>
        <Navbar />

        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center" bgcolor="#ffff">
            <Box p={1}>
              <h2 className="title-central-r">Recuperar contraseña</h2>
            </Box>
          </Box>
        </div>

        <Collapse in={activeStep === 0}>
          <div id="registrarse" style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={1}>
                <br></br>
                <br></br>
                <h4>Datos personales</h4>
                <br></br>
                <br></br>

                <Divider variant="middle" className="divider" />
              </Box>
            </Box>
          </div>

          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={6}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Container component="main" style={{ width: "570px" }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Documento</strong>
                        </InputLabel>
                        <TextField
                          select
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          id="tDocumento"
                          name="tDocumento"
                          value={tDocumento}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.tDocumento}
                          helperText={values.filterErrors.tDocumento}
                        >
                          <MenuItem value="">
                            <em>Seleccionar</em>
                          </MenuItem>
                          <MenuItem value={"DU"}>DU</MenuItem>
                          <MenuItem value={"Pasaporte"}>Pasaporte</MenuItem>
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Número de documento</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          id="nDocumento"
                          name="nDocumento"
                          value={nDocumento}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.nDocumento}
                          helperText={values.filterErrors.nDocumento}
                          onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                          onKeyPress={(e) =>
                            validateOnKeyDown(e, "onlyNumbers")
                          }
                          onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Email</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          variant="outlined"
                          label=""
                          value={email}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.email}
                          helperText={values.filterErrors.email}
                        />
                      </Grid>
                    </Grid>
                    <Box mt={5}>
                      <Grid item xs className={classes.paperB}>
                        <ReCAPTCHA
                          style={{ marginTop: "5%", padding: "4px" }}
                          sitekey={SITE_KEY}
                          onChange={responseCaptcha}
                        />
                      </Grid>
                    </Box>
                  </Container>
                </form>
              </Box>
            </Box>
          </div>
        </Collapse>

        <Collapse in={activeStep === 1}>
          <div id="registrarse" style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={1}>
                <br></br>
                <br></br>
                <h4>Confirmar codigo</h4>
                <br></br>
                <br></br>

                <Divider variant="middle" className="divider" />
              </Box>
            </Box>
          </div>

          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={6}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Container component="main" style={{ width: "570px" }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Codigo</strong>
                        </InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          id="code"
                          name="code"
                          value={code}
                          onChange={handleInputChange}
                          className={classes.root}
                          error={values.filterErrors.code}
                          helperText={values.filterErrors.code}
                          onKeyDown={(e) => validateOnKeyDown(e, "onlyNumbers")}
                          onKeyPress={(e) =>
                            validateOnKeyDown(e, "onlyNumbers")
                          }
                          onPaste={(e) => validateOnPaste(e, "onlyNumbers")}
                        />
                      </Grid>
                    </Grid>

                    <Box mt={5}>
                      <Grid item xs className={classes.paperB}>
                        <ReCAPTCHA
                          style={{ marginTop: "5%", padding: "4px" }}
                          sitekey={SITE_KEY}
                          onChange={responseCaptcha}
                        />
                      </Grid>
                    </Box>
                  </Container>
                </form>
              </Box>
            </Box>
          </div>
        </Collapse>

        <Collapse in={activeStep === 2}>
          <div id="registrarse" style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={1}>
                <br></br>
                <br></br>
                <h4>Confirmar contraseña</h4>
                <br></br>
                <br></br>

                <Divider variant="middle" className="divider" />
              </Box>
            </Box>
          </div>

          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff">
              <Box p={6}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Container component="main" style={{ width: "570px" }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">Contraseña</strong>
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type={values.showPassword ? "text" : "password"}
                          id="confPassword"
                          name="newPassword"
                          label=""
                          value={newPassword}
                          onChange={handleInputChange}
                          className={classes.root}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={values.filterErrors.newPassword}
                          helperText={values.filterErrors.newPassword}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor="standard-adornment-password">
                          <strong className="b-1-r">
                            Confirmar contraseña
                          </strong>
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type={values.showPasswordConf ? "text" : "password"}
                          id="confPassword"
                          name="confPassword"
                          label=""
                          value={confPassword}
                          onChange={handleInputChange}
                          className={classes.root}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordConf}
                                  onMouseDown={handleMouseDownPasswordConf}
                                >
                                  {values.showPasswordConf ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={values.filterErrors.confPassword}
                          helperText={values.filterErrors.confPassword}
                        />
                      </Grid>
                    </Grid>
                    <Box mt={5} style={{ width: "570px" }}>
                      <Grid item xs>
                        <p className="b-1-l">
                          La contraseña debe contener al menos 8 caracteres
                          alfanuméricos, un <br></br>número, una letra
                          mayúscula, una minúscula y no debe contener <br></br>
                          caracteres especiales.
                        </p>
                      </Grid>
                    </Box>

                    <Box mt={5}>
                      <Grid item xs className={classes.paperB}>
                        <ReCAPTCHA
                          style={{ marginTop: "5%", padding: "4px" }}
                          sitekey={SITE_KEY}
                          onChange={responseCaptcha}
                        />
                      </Grid>
                    </Box>
                  </Container>
                </form>
              </Box>
            </Box>
          </div>
        </Collapse>

        <div style={{ width: "100%" }}>
          <Box bgcolor="#ffff">
            <Grid container>
              <Grid item xs></Grid>
              <Grid item xs={8}>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </Box>
        </div>

        <div style={{ width: "100%" }}>
          <Box bgcolor="#ffff" p={4}>
            <Grid item xs className={classes.paperB}>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    Ha Completado la registración
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    VOLVER AEMPEZAR
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      color="primary"
                      className={classes.button}
                      size="large"
                      hidden={activeStep === 0}
                    >
                      ATRAS
                    </Button>

                    <Collapse in={activeStep === 0}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.button}
                        size="large"
                        type="submit"
                      >
                        SIGUIENTE
                      </Button>
                    </Collapse>

                    <Collapse in={activeStep === 1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.button}
                        size="large"
                        type="submit"
                      >
                        SIGUIENTE
                      </Button>
                    </Collapse>

                    <Collapse in={activeStep === 2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.button}
                        size="large"
                        type="submit"
                      >
                        SIGUIENTE
                      </Button>
                    </Collapse>
                  </div>
                </div>
              )}
            </Grid>
          </Box>
        </div>

        <br></br>
        <br></br>
        <Contact />
      </MediaQuery>
    </div>
  );
}
