import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import headersData from "../data";

const useStyles = makeStyles((theme) => ({
navbar : {
       cursor: 'Pointer',
      '&:hover':{
        borderBottom: '2px solid'
      },
      '&:focus': {
        outline: 'none', 
      },
}
}));

const NavbarButtons = () => {
    const { navbar } = useStyles();
    
    return (
        <>
            { 
              headersData.map(({ label, href }) => {
                return (
                    <NavLink exact to={href} alt="" key={label}>
                        <button color="inherit" className={navbar}>{label}</button>
                    </NavLink>
                  )
              }) 
            }
        </>
    )
}

export default NavbarButtons;