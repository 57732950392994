const headersData = [
    {
       label: "CÉLULAS MADRE",
       href: "/banco-celulas-madre",
     },
      {
       label: "SERVICIOS",
       href: "/servicios-smg-cells",
     }, 
     {
       label: "PREGUNTAS FRECUENTES",
       href: "/preguntas-frecuentes-celulas-madre",
     },
     {
       label: "POR QUÉ ELEGIRNOS",
       href: "/por-que-elegirnos",
     },
];

export default headersData;