import CustomInput from './CustomInput';
import { Field } from "formik";

const ContractItem = (props) => {

    const {contra, nombre, apellido, id} = props;

  return (
    <>
      <div role="group" aria-labelledby="my-radio-group" key={id}>
        <label>
          <Field
            as={CustomInput}
            name="picked"
            value={contra}
            style={{ cursor: "pointer", position: "absolute" }}
          />
          <h5
            style={{
              left: "30px",
              bottom: "12px",
              position: "relative",
              color: "#707070",
              textAlign: "left",
              letterSpacing: "var(--unnamed-character-spacing-0)",
              fontFamily: 'Red Hat Display',
              lineHeight: "25px",
              fontSize: "22px !important",
              fontWeight: "bold",
            }}
          >
            Gestión N° &nbsp; {contra} &nbsp; {nombre} &nbsp;{" "}
            {apellido} <br />
          </h5>
        </label>
      </div>
    </>
  );
};

export default ContractItem;
