export const frequentlyQuestions =[
    {   id:1,
        title: "¿QUIÉN TOMA LA MUESTRA?",
        text: "Luego del parto, tu obstetra o la recolectora de SMG Cells se encargará de tomar las muestras necesarias del cordón umbilical con el kit de recolección entregado previamente.",
        class:"frequentlyQuestionsOne"
    },
    {
        id: 2,
        title:"¿CUÁNTO TIEMPO DURAN CONGELADAS?",
        text: " No hay un plazo de vencimiento para las células madre guardadas bajo este proceso. Distintos trabajos científicos demostraron utilizar células congeladas hace más de 20 años, estando las mismas en perfecto estado.",
        class:"frequentlyQuestionsTwo"
    },
    {
        id:3,
        title:"¿QUÉ COSTO TIENE?",
        text:"Para conocer todo acerca de nuestro servicio, completá el formulario con tus datos y una asesora te contactará.",
        class:"frequentlyQuestionsThree"
    },
    {
        id:4,
        title:"¿POR QUÉ GUARDAR LAS CELULAS MADRE DE MI HIJO AL MOMENTO DE SU NACIMIENTO?",
        text: "Tener la oportunidad de guardar sus células madre significa resguardar la calidad de su salud durante toda la vida, ya que dispondrá de un elemento único que podrá ser utilizado ante eventuales enfermedades y tratamientos.",
        class:"frequentlyQuestionsFour"
    },
    {
        id:5,
        title:"¿QUIENES PUEDEN UTILIZAR LAS CELULAS MADRE DE MI HIJO? / ¿SON COMPATIBLES ENTRE HERMANOS?",
        text:"Las probabilidades de que sean compatibles genéticamente con otro miembro de la familia son las mismas que para cualquier otra persona, excepto en el caso de los hermanos de sangre, en cuyo caso las probabilidades de compatibilidad son de un 25% en las muestras de Células Madre Hematopoyéticas y un 100% en las muestras de Células Madre Mesenquimales. Actualmente las familias guardan las células madre de cada uno de sus hijos.",
        class:"frequentlyQuestionsFive"
    },
    {
        id:6,
        title:" ¿HASTA QUÉ MOMENTO DEL EMBARAZO PUEDO CONTRATARLO?",
        text:"Técnicamente, hasta el momento del parto. Aunque lo más recomendable es tomar la decisión en forma anticipada para contar con más tiempo para preparar la documentación, informarse y planificar la recolección de la muestra.",
        class:"frequentlyQuestionsSix"
    },
    {
        id:7,
        title:" ¿CUÁNDO Y CUÁNTO SE EXPANDEN LAS CÉLULAS MADRE MESENQUIMALES?",
        text: "Las células madre mesenquimales serán expandidas previo a su preservación, hasta obtener un recuento igual o mayor a 3 x 10^6. Además, ante una solicitud para una cirugía o curación, se pueden descongelar de forma programada y expandirlas nuevamente. En este caso, la cantidad de células que se obtenga dependerá del tiempo disponible entre la descongelación y su uso.",
        class:"frequentlyQuestionsSeven"
    },
]

export const whatsapp = {
    src: 'img/btn_whatsapp.png'
}