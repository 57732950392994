import {
  createTheme,
  responsiveFontSizes,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";

let theme = createTheme({
  // overrides: {
  //   MuiSvgIcon: {
  //     root: {
  //       width: "1em",
  //       height: "2em",
  //     },
  //   },
  // },
});

theme.overrides = {
  MuiOutlinedInput: {
    focused: {
      borderColor: "red",
    },
    input: {
      padding: "8px 14px",
    },
  },
};

theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    // "& .MuiButton-containedPrimary": {
    //   backgroundColor: "#00748b",
    // },
    "& .MuiButton-outlinedPrimary": {
      border: "1px solid #797979",
    },
    "& .MuiButton-outlinedSecondary": {
      border: "1px solid",
    },
    "& .MuiButton-outlinedSecondary:hover": {
      border: "1px solid #00748b",
    },
    "& label.Mui-focused": {
      color: "#00748b",
      fontSize: "15px",
    },
    // "& .MuiOutlinedInput-root": {
    //   "&.Mui-focused fieldset": {
    //     borderColor: "#00748b",
    //   },
    // },
    // "& .MuiOutlinedInput-root:focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "#00748b",
    // },
    // "& .MuiOutlinedInput-root:focused": {
    //   "& $MuiOutlinedInput-notchedOutline": {
    //     borderColor: "#00748b",
    //   },
    // },

    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#00748b",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fafafa",
      marginTop: "10px",
    },
    "& .MuiStepIcon-root": {
      backgroundColor: "#fafafa",
      zIndex: 1,
      color: "#fafafa",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      border: "2px solid gray",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
    // "& .MuiStepConnector-alternativeLabel": {
    //   top: "30px",
    //   left: "calc(-50% + 30px)",
    //   right: "calc(50% + 30px)",
    //   position: "absolute",
    // },
    "& .MuiStepIcon-text": {
      fontSize: "1rem",
      fill: "#797979",
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "13px",
    },
    "& .MuiTypography-body1": {
      fontSize: "18px",
      fontFamily: 'Red Hat Display',
      "@media (min-width:768px)": {
        fontSize: "13px",
        fontFamily: 'Red Hat Display',
      },
    },
    "& .MuiTypography-body2": {
      fontSize: "18px",
      "@media (min-width:768px)": {
        fontSize: "13px",
        fontFamily: 'Red Hat Display',
      },
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "#00748b",
      fontFamily: 'Red Hat Display',
    },
    "& .MuiDivider-middle": {
      backgroundColor: "00748b",
      border: "1px solid #00748b",
    },
    "& .MuiFormHelperText-root ": {
      color: "red",
    },
    "& .MuiStepIcon-active": {
      "& $text": {
        fill: "#ffffff",
      },
      backgroundColor: "#00748b",
      // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      color: "#00748b",
      border: "2px solid #00748b",
    },
    "& .MuiStepIcon-completed": {
      "& $text": {
        fill: "#034691",
      },
      backgroundColor: "#fafafa",
      // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      color: "#00748b",
      border: "2px #00748b",
    },
    "& .MuiStepLabel-label.MuiStepLabel-completed": {
      color: "#00758d",
    },

    "& .MuiInputBase-root": {
      color: "#707070",
      fontSize: "14px",
    },

    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336 !important",
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "72.5%",
  },
  backButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#fafafa",
    //margin: "0",
    width: "200px",
    height: "40px",
    color: "#797979",
    fontSize: "13px",
    fontWeight: "bold",
    borderColor: "#797979",
    borderRadius: "25px",
    textTransform: "capitalize",
    fontFamily: 'Red Hat Display',
    letterSpacing: "0px",
    //opacity: 1,
  },
  nextButton: {
    backgroundColor: "#00758d",
    color: "#FFFFFF",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#00758d",
    //margin: "0",
    width: "200px",
    height: "40px",
    fontSize: "13px",
    //fontWeight: "bold",
    //borderColor: "#797979",
    borderRadius: "25px",
    textTransform: "capitalize",
    fontFamily: 'Red Hat Display',
    letterSpacing: "0px",
    opacity: 1,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paperB: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 24,
    left: "calc(-50% + 25px)",
    right: "calc(50% + 25px)",
    position: "absolute",
  },
  active: {
    "& $line": {
      borderColor: "#00758d",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#00758d",
    },
  },
  line: {
    borderColor: "#797979",
    borderTopWidth: 2,
    //borderRadius: 1,
  },
})(StepConnector);

export { theme, useStyles, QontoConnector };
