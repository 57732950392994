import React, { useState } from "react";
import { Paper, CssBaseline } from "@material-ui/core";

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import DatosPersonales from "./Forms/DatosPersonales";
import Confirmacion from "./Forms/Confirmacion";
import Validacion from "./Forms/Validacion";
import FormInfo from "./FormInfo";

import validationSchema from "./FormModel/validationSchema";
import checkFormModel from "./FormModel/checkFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";

import { useStyles, QontoConnector } from "./styles";
import "./registrarse.css";
import {registerUserActions, validatePinActions, validateUserActions} from "../../actions/validateUserActions";
import {errorAlert, messageAlert, popUp} from "../../utils/alerts/alerts";
import {SITE_KEY} from "../../constants";
import Loading from "../../utils/Loading";
import ScrollToTopOnMount from '../../utils/scrollHeader';

const steps = ["Datos personales", "Confirmación", "Vinculación"];
const { formId, formField } = checkFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <DatosPersonales formField={formField} />;
    case 1:
      return <Confirmacion formField={formField} />;
    case 2:
      return <Validacion formField={formField} />;
    default:
      return <div>No se encontró</div>;
  }
}

export default function Registrarse() {
  const classes = useStyles();
  const recaptchaRef = React.createRef();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  let tokenRecaptcha = '';
  let isPotentialUser = true;
  let stateValidatePin = true;
  let stateRegiterUser = true;
  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [values, setValues] = React.useState({
    showPassword: false,
    show: false,
    isOpen: false,
    isOpenLoading: false,
    messageModal: "",
    cardType: undefined,
    filterErrors: {},
  });

  //Loading
  function handleOpenLoading() {
    setValues({ isOpenLoading: true });
  }

  function handleCloseLoading() {
    setValues({ isOpenLoading: false });
  }

  async function _submitForm(values) {
    const urlRegistration = '/registerAffiliate';
    const tokenRc = await recaptchaRef.current.executeAsync();
    recaptchaRef.current?.reset();
    if(tokenRc){
      handleOpenLoading();
      const data = {
        recaptchaResponse: tokenRecaptcha,
        ...values
      }
      registerUserActions(urlRegistration, values).then( () => {
            handleCloseLoading();
            if(stateRegiterUser === true){
              messageAlert("Success","¡Felicitaciones!","Has sido registrado con éxito en nuestro portal web. " +
                  "Podrás ingresar con tu tipo y número de documento y clave. Muchas gracias.").then(r => window.location.replace("/"))
              return true;
            }
      }
      )
      .catch(
          () => {
            handleCloseLoading();
            errorAlert("Tuvimos un problema procesando tu solicitud","Volve a intentarlo en unos minutos por favor.");
            return false;
          }
      );

    }
    return false;
  }

  async function _submitValidateUserForm(values) {
    const urlValidateUser = '/validateUser';
    const tokenRc = await recaptchaRef.current.executeAsync(); //grecaptcha.execute();
    recaptchaRef.current?.reset();
    if(tokenRc){
      handleOpenLoading();
      const data = {
        recaptchaResponse: tokenRc,
        ...values
      }

      validateUserActions(urlValidateUser, data).then( () => {
            handleCloseLoading();
            return true;
          }
      )
      .catch(
          () => {
            handleCloseLoading();
            errorAlert("Tuvimos un problema procesando tu solicitud","Volve a intentarlo en unos minutos por favor.");
            return false;
          }
      );
      return true;
    } else {
      handleCloseLoading();
      errorAlert("Se produjo un inconveniente en la autenticación via recaptcha","Volve a intentarlo por favor.");
      return false;
    }
  }

  async function _submitValidatePinForm(values) {
    const urlValidatePin = '/validateRegistrationPin';
    const tokenRc = await recaptchaRef.current.executeAsync();
    recaptchaRef.current?.reset();
    if(tokenRc){
      handleOpenLoading();
      const data = {
        recaptchaResponse: tokenRc,
        ...values
      }

      validatePinActions(urlValidatePin, data).then( () => {
            handleCloseLoading();
            return true;
          }
      )
      .catch(
          () => {
            handleCloseLoading();
            errorAlert("Tuvimos un problema procesando tu solicitud","Volve a intentarlo en unos minutos por favor.");
            return false;
          }

      );
      return true;
    } else {
      errorAlert("Se produjo un inconveniente en la autenticación via recaptcha","Volve a intentarlo por favor.");
      return false;
    }
  }

  async function _handleSubmit(values, actions) {
    if(activeStep === 0){
      const next = await _submitValidateUserForm(values);
      if(next === true){
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }else{
      if (isLastStep === true) {
        await _submitForm(values);
      } else {
        stateValidatePin = await _submitValidatePinForm(values);
        if(stateValidatePin === true){
          setActiveStep(activeStep + 1);
          actions.setTouched({});
          actions.setSubmitting(false);
        }
      }
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  String.prototype.isAlphaNumeric = function () {
    var regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    return this.match(regExp);
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Navbar />
      <ScrollToTopOnMount />
      <div className={classes.root} id="containerRegister">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className={classes.stepper}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <FormInfo />
          ) : (
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting, isValid, dirty, values }) => (
                <Form id={formId}>
                  {_renderStepContent(activeStep)}

                  <div className={classes.buttons}>
                    <div className={classes.wrapper}>
                      {activeStep !== 0 && (
                        <Button
                          onClick={_handleBack}
                          className={classes.backButton}
                          color="primary"
                          variant="outlined"
                        >
                          Volver
                        </Button>
                      )}
                      <Button
                        // disabled={
                        //   !(isValid && dirty) ||
                        //   isSubmitting
                        // }
                        disabled={
                          isLastStep
                            ? values.terms === false || values.contrato === ""
                            : activeStep === 1
                            ? values.code.length !== 6
                            : values.nombre === "" ||
                              values.apellido === "" ||
                              values.tipo === "" ||
                              values.documento === "" ||
                              values.fecha === "" ||
                              values.genero === "" ||
                              values.email === "" ||
                              values.confirmEmail === "" ||
                              values.clave === "" ||
                              values.clave.length < 8 ||
                              !values.clave.isAlphaNumeric()
                        }
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.nextButton}
                      >
                        {isLastStep
                          ? isSubmitting
                            ? ""
                            : "Enviar"
                          : "Siguiente"}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                  <ReCAPTCHA
                      ref={recaptchaRef}
                      style={{ marginTop: "5%" }}
                      size="invisible"
                      sitekey={SITE_KEY}
                  />
                </Form>
              )}
            </Formik>
          )}
        </React.Fragment>
        <Loading
            isOpenLoading={values.isOpenLoading}
            handleCloseLoading={handleCloseLoading}
        ></Loading>
      </div>

      <Contact />
    </React.Fragment>
  );
}
