import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    logo: {
      fontFamily: 'Red Hat Display',
      fontWeight: 600,
      color: "#00748b",
      textAlign: "center",
    }
  }));
  
const NavbarLogo = () => {
    const { logo } = useStyles();

    return (
        <>
            <Typography variant="h6" component="h1" className={logo}>
                <NavLink className="navbar-logo img-cells left-p page-scroll" to="/">
                    <img style={{"width": "100%", "maxWidth": "250px", padding: "10px"}} src="img/Swiss_Medical_CELLS_Logo.png" alt="new_logo"/>
                </NavLink>{" "}
            </Typography>
        </>
    )
}

export default NavbarLogo;