export const dataBeneficiosCriopreservar ={
        titleBeneficios: "BENEFICIOS DE CRIOPRESERVAR SUS CÉLULAS MADRE",
        paragraph : "Su aplicación ya está comprobada para algunas patologías y está en proceso de investigación para otras.",
        subtitleAplicacion: [
            {
                title: "Aplicación comprobada ",
                type: "bold"
            },
            {
                title: "para patologías como:",
                type: "normal"
            }
        ] ,
        subtitleAplicacionMobile : "APLICACIÓN COMPROBADA",
        subtitleProceso: [
            {
                title: "En ",
                type: "normal"
            },
            {
                title: "proceso de investigación",
                type: "bold"
            },
            {
                title: "para:",
                type: "normal"
            }
        ],
        subtitleProcesoMobile :"PROCESO DE INVESTIGACIÓN",
        listAplicacion:[
            {
                text: "Anemia aplásica y hereditaria"
            },
            {
                text:"Leucemias"
            }
            ,
            {
                text:"Linfomas"
            }
            ,
            {
                text:"Osteoporosis"
            }
            ,
            {
                text:"Mieloma múltiple"
            }
            ,
            {
                text:"Neuroblastoma"
            }
            ,
            {
                text:"Enfermedades inmunes"
            }
            ,
            {
                text:"Trastornos mieloproliferativos"
            }
            ,
            {
                text:"Trastornos de almacenamiento de lípidos"
            }
        ],
        listProceso:[
            {
                text:"Diabetes"
            },
            {
                text:"Parkinson"
            },
            {
                text:"Parálisis cerebral"
            }
            ,
            {
                text:"Infarto del miocardio"
            }
            ,
            {
                text:"Esclerosis múltiple"
            }
            ,
            {
                text:"Quemaduras de piel"
            }
            ,
            {
                text:"Lesiones de córnea"
            }
            ,
            {
                text:"Regeneración de cartílagos"
            }
            ,
            {
                text:"Enfermedad vascular periférica"
            },
            {
                text:"Lesión de médula espinal"
            },
            {
                text:"Colagenopatía"
            }
        ]

    }
    
