import React from "react";
import MediaQuery from "react-responsive";
import Box from "@material-ui/core/Box";
import "../../Contact/contact.css";

export default function Footer() {
  //const classes = useStyles();
  return (
    <div>
      <MediaQuery maxDeviceWidth={767}>
        {/* Small devices - Portrait */}
        <div id="contact" className="custom-contact">
          <div className="container">
            <div className="section-title col-xs-12 col-md-5 custom-smg-cells">
              </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery minDeviceWidth={768} and maxDeviceWidth={991}>
        <div style={{ width: "100%", backgroundColor: "#00758d" }}>
          <Box display="flex" justifyContent="center">
            <Box p={1}>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              
              <br />
              
              <br />
              
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Box>
            <Box p={1}>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Box>
            <Box p={1} paddingLeft="60">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              
              <br />
              
              <br />
              
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Box>
          </Box>
        </div>
      </MediaQuery>
      <MediaQuery minDeviceWidth={992}>
        <div style={{ width: "100%", backgroundColor: "#00758d" }}>
          <Box display="flex" justifyContent="center">
            <Box p={1}>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              
              <br />
              
              <br />
              
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Box>
            <Box p={1}>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Box>
            <Box p={1} paddingLeft="60">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              
              <br />
              
              <br />
              
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Box>
          </Box>
        </div>
      </MediaQuery>
    </div>
  );
}
