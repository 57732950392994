import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import { TextField } from "@material-ui/core";
import { isWidthDown } from "@material-ui/core/withWidth";

export default function DatePickerField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  }

  const dateLimit = new Date();
  dateLimit.setFullYear(dateLimit.getFullYear() - 18);

  return (
    <TextField
      variant="outlined"
      margin="normal"
      type="date"
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: "1920-01-01",
        max: dateLimit.toISOString().slice(0, 10),
      }}
      fullWidth={isWidthDown("sm")}
      {...field}
      {...rest}
    />
  );
}
