export const whyChooseOne =[
{
    text:"Una de nuestras principales fortalezas es el conocimiento y experiencia en obstetricia y neonatología de dos entidades referentes en el país como la",
    style: "normal"
},
{
    text:" Clínica y Maternidad Suizo Argentina ",
    style: "bold"
},
{
    text:"y el ",
    style:"normal"
},
{
    text:"Sanatorio de los Arcos",
    style:"bold"
},
{
    text:", que poseen la más avanzada tecnología, recursos profesionales capacitados y normas de bioseguridad que garantizan la calidad de los procesos.",
    style: "normal"
}

]

export const whyChooseTwo =[
    {
        text: "Además, contamos con el apoyo y respaldo de los profesionales de nuestros ",
        style: "normal"
    },
    {
        text:"Swiss Medical Center",
        style: "bold"
    },
    {
        text: ", y destacados especialistas que integran la cartilla de ",
        style: "normal"
    },
    {
        text:"Swiss Medical Medicina Privada.",
        style:"bold"
    }

]

export const whyChooseThree =[
    {
        text:"En ",
        style:"normal"
    },
    {
        text:"SMG Cells",
        style:"bold"
    },
    {
        text:" trabajamos para asegurar la máxima calidad en nuestros servicios bajo estrictos protocolos de cuidado del paciente y recolección, procesamiento y expansión y criopreservación de las muestras con control permanente y trazabilidad del material obtenido, que responden a las normas más avanzadas en el campo de las células madre, con garantía de disponibilidad inmediata.",
        style: "normal"
    }

]

export const whyChooseFour = [
    {
        textNormal:"Gerente General: ",
        textBold:"Lic. Martin Lezama"
    },
    {
        textNormal:"Directora Técnica: ",
        textBold:"Dra. Susana Anhel"
    },
    {
        textNormal:" Director Comercial: ",
        textBold:"Lic. Julio Diez "
    },
    {
        textNormal:"Coordinación Comercial: ",
        textBold:"Flavia Roxana Acevedo"
    },
    {
        textNormal:"Coordinación Administración y Operaciones: ",
        textBold:"Constanza Ferrero Allende"
    },
]

export const whatsapp = {
    src: 'img/btn_whatsapp.png'
}