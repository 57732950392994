import {useState} from "react";
import './preguntasFrecuentesCard.css';

const PreguntasFrecuentesCard = ({question}) => {
    const [questionOne,setQuestionOne] = useState(false);

    const handleQuestionOne =()=>{
      setQuestionOne(!questionOne);
    }
    return (
        <div className = "container-question ">
                <div className = "container_question_title" onClick = {()=>{handleQuestionOne()}}>
              <h4>{question.title}</h4>
              <img 
                className="flecha_accordion_mdpi_flecha_pf"
                src="img/flecha-accordion-mdpi.png"
                alt="flecha"
               />
            </div>  
          
            <div className = {questionOne ?"container_question_p ":"container_question_p_none "}>
              <p>{question.text}</p>
           
            </div>
      </div>
    )
}

export default PreguntasFrecuentesCard
