export default () => {
  const max = 99999999999999999;
  const min = 1;
  let id = (Math.floor(Math.random() * (max - min) + min)).toString();
  // const nav = window.navigator;
  // const screen = window.screen;
  // id += nav.userAgent.replace(/\D+/g, '');
  // id += nav.plugins.length;
  // id += screen.height || '';
  // id += screen.width || '';
  // id += screen.pixelDepth || '';

  return id;
};
