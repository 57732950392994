import {TERMS_CONDITIONS,
TERMS_CONDITIONS_SUCCESS,
TERMS_CONDITIONS_ERROR,
TERMS_CONDITIONS_SUCCESS_FALSE,
TERMS_CONDITIONS_SUCCESS_TRUE,
    TERMS_CONDITIONS_LOADING }
from '../types'

const initialState = {
    error: null,
    loading: false,
    termsConditionsState: false,
    termsConditions: null,
    idTerminos: null,
    idUser: null
}

export default function termsConditionsReducer (state = initialState, action){
    switch(action.type){
        case TERMS_CONDITIONS:
            return{
                ...state,
                termsConditionsState:action.payload
            }
        case TERMS_CONDITIONS_LOADING:
            return {
                ...state,
                error:null,
                loading: action.payload
            }
        case TERMS_CONDITIONS_SUCCESS_TRUE:
            return{
                ...state,
                termsConditionsState:true,
                idTerminos: action.payload.terminos,
                termsConditions:action.payload.texto
            }
        case TERMS_CONDITIONS_SUCCESS_FALSE:
            return{
                ...state,
                termsConditionsState:false,
                idTerminos: action.payload.terminos,
                termsConditions:action.payload.texto
            }
        case TERMS_CONDITIONS_ERROR:
            return{
                ...state,
                termsConditionsState:false,
                termsConditions:null,
                error:action.payload
            } 
        default:
            return state          
    }
} 