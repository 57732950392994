/* eslint-disable import/prefer-default-export */
import { find, isUndefined, omit } from 'lodash';

const defaultError = {
  title: 'En este momento no es posible realizar el pago',
  message: 'Por favor, intentalo más tarde.',
};

const contactBankEmisorError = {
  title: 'No pudimos realizar el pago',
  message:
    'Por favor, verificá los datos de la tarjeta ingresada. Si el error persiste, contactate con el banco emisor.',
};

const verifyDataEntered = {
  title: 'No pudimos realizar el pago',
  message: 'Por favor, verificá los datos de la tarjeta ingresada.',
};

const errors = [
  {
    id: '-2',
    ...defaultError,
  },
  {
    id: '-7',
    detalle: 'VD-ERR-NUMTAR',
    ...verifyDataEntered,
  },
  {
    id: '-7',
    detalle: 'VD-ERR-TARJNUM',
    ...verifyDataEntered,
  },
  {
    id: '1',
    detalle: 'PEDIR AUTORIZACION',
    ...contactBankEmisorError,
  },
  {
    id: '2',
    detalle: 'PEDIR AUTORIZACION',
    ...contactBankEmisorError,
  },
  {
    id: '4',
    detalle: 'CAPTURAR TARJETA',
    ...contactBankEmisorError,
  },
  {
    id: '5',
    detalle: 'DENEGADA',
    title: 'No pudimos realizar el pago',
    message: 'Por favor, contactate con el banco emisor.',
  },
  {
    id: '7',
    detalle: 'RETENGA Y LLAME',
    ...contactBankEmisorError,
  },
  {
    id: '8',
    // detalle: 'Ingreso Manual Incorrecto',
    ...verifyDataEntered,
  },
  {
    id: '13',
    detalle: 'MONTO INVALIDO',
    ...defaultError,
  },
  {
    id: '14',
    detalle: 'TARJETA INVALIDA',
    ...contactBankEmisorError,
  },
  {
    id: '25',
    detalle: 'NO EXISTE ORIGINAL',
    ...contactBankEmisorError,
  },
  {
    id: '28',
    detalle: 'SERVICIO NO DISPONIBLE',
    ...defaultError,
  },
  {
    id: '30',
    detalle: 'ERROR EN FORMATO',
    ...defaultError,
  },
  {
    id: '31',
    detalle: 'THE PARAMETER MUST BE SPECIFIED.',
    ...defaultError,
  },
  {
    id: '38',
    detalle: 'EXCEDE ING.DE PIN',
    title: 'No pudimos realizar el pago',
    message:
      'Superaste la cantidad de intentos permitidos para ingresar el pin correctamente. Por favor, contactate con el banco emisor.',
  },
  {
    id: '39',
    detalle: 'Ingreso Manual Incorrecto',
    ...verifyDataEntered,
  },
  {
    id: '43',
    detalle: 'RETENER TARJETA',
    ...contactBankEmisorError,
  },
  {
    id: '46',
    detalle: 'TARJETA NO VIGENTE',
    title: 'No pudimos realizar el pago',
    message: 'La tarjeta ingresada aún no está vigente.',
  },
  {
    id: '47',
    detalle: 'PIN REQUERIDO',
    ...defaultError,
  },
  {
    id: '49',
    detalle: 'ERROR FECHA VENCIM.',
    ...defaultError,
  },
  {
    id: '50',
    detalle: 'ENTREGA SUPERA LIMITE',
    ...contactBankEmisorError,
  },
  {
    id: '51',
    detalle: 'FONDOS INSUFICIENTES',
    title: 'No pudimos realizar el pago',
    message: 'Fondos insuficientes.',
  },
  {
    id: '53',
    detalle: 'CUENTA INEXISTENTE',
    ...contactBankEmisorError,
  },
  {
    id: '54',
    detalle: 'TARJETA VENCIDA',
    title: 'No pudimos realizar el pago',
    message: 'La tarjeta ingresada expiró.',
  },
  {
    id: '55',
    detalle: 'PIN INCORRECTO',
    title: 'No pudimos realizar el pago',
    message: 'El pin ingresado es incorrecto, por favor intentalo nuevamente.',
  },
  {
    id: '56',
    detalle: 'TARJ. NO HABILITADA',
    ...contactBankEmisorError,
  },
  {
    id: '57',
    detalle: 'TRANS. NO PERMITIDA',
    ...contactBankEmisorError,
  },
  {
    id: '58',
    detalle: 'SERVICIO INVALIDO',
    ...contactBankEmisorError,
  },
  {
    id: '61',
    detalle: 'EXCEDE LIMITE',
    title: 'No pudimos realizar el pago',
    message:
      'El importe a pagar excede el límite de tu tarjeta. Por favor contactate con el banco emisor.',
  },
  {
    id: '65',
    detalle: 'EXCEDE LIM. TARJETA',
    title: 'No pudimos realizar el pago',
    message:
      'El importe a pagar excede el límite remanente de tu tarjeta. Por favor intentá con otro medio de pago o contactate con el banco emisor.',
  },
  {
    id: '76',
    detalle: 'LLAMAR AL EMISOR',
    ...contactBankEmisorError,
  },
  {
    id: '91',
    detalle: 'EMISOR FUERA LINEA',
    ...contactBankEmisorError,
  },
  {
    id: '94',
    detalle: 'NRO. SEC. DUPLICAD',
    ...defaultError,
  },
  {
    id: '96',
    detalle: 'ERROR EN SISTEMA',
    ...defaultError,
  },
  {
    id: '97',
    detalle: 'Error en sistema host',
    ...defaultError,
  },
  {
    id: '98',
    detalle: 'VER RECHAZO EN TICKET',
    ...contactBankEmisorError,
  },
];

export const getErrorExecutePayment = (query = { id: 'default' }) => {
  const error = find(errors, query);
  return isUndefined(error) ? defaultError : omit(error, ['id', 'detalle']);
};
