import checkFormModel from "./checkFormModel";
const {
  formField: {
    nombre,
    apellido,
    documento,
    tipo,
    genero,
    fecha,
    email,
    confirmEmail,
    clave,
    code,
    contrato,
    terms,
  },
} = checkFormModel;

export default {
  [nombre.name]: "",
  [apellido.name]: "",
  [documento.name]: "",
  [tipo.name]: "",
  [genero.name]: "",
  [fecha.name]: "",
  [email.name]: "",
  [confirmEmail.name]: "",
  [clave.name]: "",
  [code.name]: "",
  [contrato.name]: "",
  [terms.name]: false,
};
