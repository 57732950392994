import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  root: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#00748b",
    },
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: "700",
  },
  title: {
    marginTop: theme.spacing(2),
  },
  expandIcon: {
    "&$expanded": {
      transform: "rotate(0deg)",
    },
  },
  expanded: {},
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightRegular,
  },
  switch_track: {
    backgroundColor: "#c6c5c5",
  },
  switch_base: {
    color: "#f1f1f1",
    "&.Mui-disabled": {
      color: "#c6c5c5",
    },
    "&.Mui-checked": {
      color: "#00748b",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#00748b",
    },
  },
  switch_primary: {
    "&.Mui-checked": {
      color: "#00748b",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#00748b",
    },
  },
  color: {
    backgroundColor: "#F6F6F6",
  },
  activeButton: {
    backgroundColor: "#fafafa",
    height: "4em",
    width: "90%",
    color: "#00748b",
    fontSize: "13px",
    borderColor: "#00748b",
    borderRadius: "25px",
    textTransform: "capitalize",
    fontFamily: 'Red Hat Display',
    letterSpacing: "0px",
  },
}));
