export const cyclicLineTitle = "CYCLIC LINE"
export const cyclicLineDetalle =[
{   id:1,
    title: "RECOLECCIÓN",
    text: "Con autorización del obstetra, un profesional interviene el cordón umbilical para obtener la sangre de su interior y/o una sección del mismo.",
    src: "img/new_recoleccion.png"
},
{
    id:2,
    title: "PROCESAMIENTO",
    text: "Se analizan las muestras para saber si son aptas para ser procesadas y se separan los componentes de la sangre hasta llegar a las células madre.",
    src: "img/new_procesamiento.png"
},
{
    id:3,
    title: "EXPANSIÓN",
    text: "Las células madre mesenquimales son expandidas mediante cultivo celular hasta obtener un recuento igual o mayor a 3 x 10⁶ .",
    src: "img/new_expansion.png"
},
{
    id:4,
    title:"CRIOPRESERVACIÓN",
    text: "Las muestras se someten a un descenso controlado y programado de temperatura, luego se las deposita en los termos de criopreservación y se sumergen en nitrógeno líquido.",
    src: "img/new_criopreservacion.png"
}
]

export const cyclicLineParagraph = "Durante todos los procesos queda garantizada la trazabilidad de ambas muestras mediante el código de barras orginal."

export const condiciones = ""

export const cyclicLineDetalleMobile =[
    {   id:1,
        title: "1 - Recolección: ",
        text: "Con autorización del obstetra, un profesional interviene el cordón umbilical para obtener la sangre de su interior y/o una sección del mismo.",
        src: "img/new_recoleccion.png"
    },
    {
        id:2,
        title: "2 - Procesamiento: ",
        text: "Se analizan las muestras para saber si son aptas para ser procesadas y se separan los componentes de la sangre hasta llegar a las células madre.",
        src: "img/new_procesamiento.png"
    },
    {
        id:3,
        title: "3 - Expansión: ",
        text: "Las células madre mesenquimales son expandidas mediante cultivo celular hasta obtener un recuento igual o mayor a 3 x 10⁶.",
        src: "img/new_expansion.png"
    },
    {
        id:4,
        title:"4 - Criopreservación: ",
        text: "Las muestras se someten a un descenso controlado y programado de temperatura, luego se las deposita en los termos de criopreservación y se sumergen en nitrógeno líquido.",
        src: "img/new_criopreservacion.png"
    }
    ]