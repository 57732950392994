import{
    ADVICE_SUCCESS,
    ADVICE_ERROR,
    ADVICE_LOADING,
    ADVICE_RESET
} from '../types'

const initialState ={
    loading: false,
    error:null,
    data:null,
}

export default function adviceReducer(state = initialState,action){
    switch(action.type){
        case ADVICE_LOADING:
        return{
            ...state,
            error:null,
            data:null,
            loading: action.payload
        }
        case ADVICE_SUCCESS:
            return{
                ...state,
                loading:false,
                data:action.payload
            }
        case  ADVICE_ERROR:
            return{
                ...state,
                loading:false,
                data: null,
                error: action.payload
            }  
        case ADVICE_RESET:
            return{
                ...state,
                loading:false,
                data:null,
                error:null
            }    
        default:
            return state
    }    
}