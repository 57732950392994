const CustomInput = (props) => {

    function check() {
        var ele = document.getElementsByName("picked");
        var flag = 0;
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].checked) flag = 1;
        }
        if (flag == 1) document.getElementById("choose").disabled = false;
      }
    
    return (
    <>
        <input
        className="my-custom-input"
        type="radio"
        {...props}
        onClick={check()}
        />
    </>
    );
}

export default CustomInput;
    
    