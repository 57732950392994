export const TERMS_CONDITIONS = 'TERMS_CONDITIONS'
export const TERMS_CONDITIONS_SUCCESS = 'TERMS_CONDITIONS_SUCCESS'
export const TERMS_CONDITIONS_SUCCESS_TRUE = 'TERMS_CONDITIONS_SUCCESS_TRUE'
export const TERMS_CONDITIONS_SUCCESS_FALSE = 'TERMS_CONDITIONS_SUCCESS_FALSE'
export const TERMS_CONDITIONS_LOADING = 'TERMS_CONDITIONS_LOADING'
export const TERMS_CONDITIONS_ERROR = 'TERMS_CONDITIONS_ERROR'
export const ADVICE_SUCCESS = 'ADVICE_SUCCESS'
export const ADVICE_ERROR =  'ADVICE_ERROR'
export const ADVICE_LOADING =  'ADVICE_LOADING'
export const ADVICE_RESET = "ADVICE_RESET"
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_ERROR =  'AUTH_LOGIN_ERROR'
export const AUTH_LOGIN_LOADING =  'AUTH_LOGIN_LOADING'
export const AUTH_LOGIN_RESET = 'AUTH_LOGIN_RESET'
export const ADVISORY_RESET = 'ADVISORY_RESET'
export const ADVISORY_ERROR = 'ADVISORY_ERROR'
export const ADVISORY_LOADING = 'ADVISORY_LOADING'
export const ADVISORY_SUCCESS = 'ADVISORY_SUCCESS'