import React from 'react'
import ServiciosDesktop from '../ServiciosDesktop/ServiciosDesktop'
import ServiciosMobile from '../ServiciosMobile/ServiciosMobile'
import {planesServicios} from './data'
import './tablaServicios.css'

const TablaServicios = () => {
    return (
        <>
            <div className = "tablaServicios">
                <div className = "containerServiceMobile">
                    {planesServicios.map((plan)=>(<ServiciosMobile key= {plan.id} title = {plan.title} data = {plan.plan} color = {plan.color}/>))}
                </div>
            </div>
            <div className='containerServiceDesktop'>
                <ServiciosDesktop serviceOne={planesServicios[0]} serviceTwo={planesServicios[1]} serviceThree={planesServicios[2]} plans={planesServicios[0].plan}/>
            </div>
        </>
    )
}

export default TablaServicios
