/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-useless-escape */
function buildRules(ruleTuples) {
  return ruleTuples.map(tuple => ({
    name: tuple[0],
    rule: tuple[1],
  }));
}

function getBrowserRules() {
  return buildRules([
    ['aol', /AOLShield\/([0-9\._]+)/],
    ['edge', /Edge\/([0-9\._]+)/],
    ['yandexbrowser', /YaBrowser\/([0-9\._]+)/],
    ['vivaldi', /Vivaldi\/([0-9\.]+)/],
    ['kakaotalk', /KAKAOTALK\s([0-9\.]+)/],
    ['samsung', /SamsungBrowser\/([0-9\.]+)/],
    ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
    ['phantomjs', /PhantomJS\/([0-9\.]+)(:?\s|$)/],
    ['crios', /CriOS\/([0-9\.]+)(:?\s|$)/],
    ['firefox', /Firefox\/([0-9\.]+)(?:\s|$)/],
    ['fxios', /FxiOS\/([0-9\.]+)/],
    ['opera', /Opera\/([0-9\.]+)(?:\s|$)/],
    ['opera', /OPR\/([0-9\.]+)(:?\s|$)$/],
    ['ie', /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/],
    ['ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
    ['ie', /MSIE\s(7\.0)/],
    ['bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/],
    ['android', /Android\s([0-9\.]+)/],
    ['ios', /Version\/([0-9\._]+).*Mobile.*Safari.*/],
    ['safari', /Version\/([0-9\._]+).*Safari/],
    ['facebook', /FBAV\/([0-9\.]+)/],
    ['instagram', /Instagram\s([0-9\.]+)/],
    ['ios-webview', /AppleWebKit\/([0-9\.]+).*Mobile/],
  ]);
}

function parseUserAgent(userAgentString) {
  const browsers = getBrowserRules();
  if (!userAgentString) {
    return null;
  }

  const detected =
    browsers
      .map(browser => {
        const match = browser.rule.exec(userAgentString);
        let version = match && match[1].split(/[._]/).slice(0, 3);

        if (version && version.length < 3) {
          version = version.concat(version.length === 1 ? [0, 0] : [0]);
        }

        return (
          match && {
            name: browser.name,
            version: version.join('.'),
          }
        );
      })
      .filter(Boolean)[0] || null;

  return detected;
}

function detect() {
  if (typeof navigator !== 'undefined')
    return parseUserAgent(navigator.userAgent);
  return null;
}

export default () => {
  const browser = detect();
  if (browser != null) return `${browser.name} ${browser.version}`;
  return 'Browser undefined';
};
