import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import Avatar from "@material-ui/core/Avatar";
import EmailIcon from "@material-ui/icons/Email";
import { useStyles } from "./styles";

import { InputOtpField } from "../FormFields";
import { useFormikContext } from "formik";

import Timer from "../FormFields/Timer";

export default function Confirmacion(props) {
  const {
    formField: { code },
  } = props;

  const { values: formValues } = useFormikContext();

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs>
            <Typography style={{ color: "#00748b", fontFamily : 'Red Hat Display' }} variant="h4" gutterBottom>
              Confimá tu e-mail
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="center">
          <Grid item xs>
            <Grid container direction="column" alignItems="center">
              <Grid item xs>
                <Avatar className={classes.avatar}>
                  <EmailIcon className={classes.phone} />
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h5" gutterBottom style={{fontFamily : 'Red Hat Display'}}>
                  Te enviamos un e-mail a{" "}
                  <span style={{ color: "#00748b" , fontFamily : 'Red Hat Display' }}>
                    {formValues.confirmEmail}
                  </span>{" "}
                  con el código de confirmación.
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h5" gutterBottom style={{fontFamily : 'Red Hat Display'}}>
                  Ingresalo para continuar.
                </Typography>
              </Grid>
              <Grid item xs>
                <InputOtpField name={code.name} />
              </Grid>

              <Timer />
              <Grid item xs>
                <Typography
                  variant="p"
                  display="block"
                  className={classes.menuText}
                  gutterBottom
                  style={{fontFamily : 'Red Hat Display'}}
                >
                  Recordá revisar también tu bandeja de correo no deseado.
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" gutterBottom style={{fontFamily : 'Red Hat Display'}}>
                  Tenés 5 minutos para activarlo, de lo contrario tendrás que
                  registrarte nuevamente.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider className="divider-end" />
    </React.Fragment>
  );
}
