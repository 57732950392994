
import {generatePin, registerUser, validateUser, validationPin} from '../utils/backendRequest'
import { messageAlert } from "../utils/alerts/alerts";

export async function validateUserActions (url, data){
    const validationUser = await Promise.all([
        validateUser(url, data)
    ]);
    if(validationUser[0].data.status !== 200){
        messageAlert('info','Aviso',validationUser[0].data.message);
        return false;
    }else{
        //generación de pin y envío del mismo al usuario.
        const datos = {
            "nombre": data.nombre,
            "apellido": data.apellido,
            "email": data.email
        }
        const urlGenerationPin = '/getRegistrationPin';
        const generationPin = await Promise.all([
            generatePin(urlGenerationPin, datos)
        ]);

        if(generationPin[0].data.status !== 200) {
            messageAlert('info', 'Aviso', generationPin[0].data.message);
            return false;
        }
        return true;
    }

}

export async function validatePinActions (url, data){
    const datos = {
        'email': data.email,
        'pin': data.code
    }
    const validatePin = await Promise.all([
        validationPin(url, datos)
    ]);
    if(validatePin[0].status !== 200){
        messageAlert('info','Aviso',validatePin[0].data.message);
        return false;
    }
    return true;
}

export async function registerUserActions (url, data){
    const registrationUser = await Promise.all([
        registerUser(url, data)
    ]);
    if(registrationUser[0].data.status !== 200){
        messageAlert('info','Aviso',registrationUser[0].data.message);
        return false;
    }
    return true;
}