const initialState = {
    token: null,
    nombre: null,
    apellido: null,
    expiresIn: null,
    docu_tipo: null,
    docu_nro: null,
    id_user: null,
    userToken: null
}

const UPDATE_INSTANCE = 'UPDATE_INSTANCE';
const REMOVE_INSTANCE = 'REMOVE_INSTANCE';

export const updateInstance = (values) => {
    return {
        type: UPDATE_INSTANCE,
        payload: values
    }
}

export const removeInstance = () => {
    return {
        type: REMOVE_INSTANCE
    }
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_INSTANCE: 
            return {
                ...state,
                token: action.payload.token,
                userToken: action.payload.userToken,
                nombre: action.payload.nombre,
                apellido: action.payload.apellido,
                expiresIn: action.payload.expiresIn,
                docu_tipo: action.payload.docu_tipo,
                docu_nro: action.payload.docu_nro,
                id_user: action.payload.id_user
            }
        case REMOVE_INSTANCE:
            return {
                token: null,
                userToken: null,
                name: null,
                lastName: null,
                expiresIn: null
            }
        default:
            return state;
    }
}

export default reducer