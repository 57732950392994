import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Fade,
  Slide,
  Grow,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { size, capitalize } from "lodash";
import clsx from "classnames";

const variantTransitionsComponent = {
  fade: Fade,
  slide: Slide,
  grow: Grow,
  zoom: Zoom,
};

const ModalSmg = ({
  id,
  open,
  title,
  maxWidth = "xs",
  scroll = "paper",
  TransitionComponent = "fade",
  children,
  disableBackdropClick = true,
  disableEscapeKeyDown = true,
  closeIconButton = false,
  noActions = false,
  onClose,
  ButtonsProps = [],
  dividers = "actions",
  ...otherProps
}) => {
  const TransitionElement = useMemo(
    () => variantTransitionsComponent[TransitionComponent],
    [TransitionComponent]
  );
  const ariaDescribedby = `modal-${id}-dialog-title`;
  const ariaLabelledby = `modal-${id}-dialog-description"`;

  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      TransitionComponent={TransitionElement}
      onClose={onClose}
      scroll={scroll}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
      {...otherProps}
    >
      {title && (
        <DialogTitle id={ariaLabelledby} disableTypography>
          <h4>{title}</h4>
          {closeIconButton ? (
            <Button aria-label="close" onClick={onClose}>
              Cerrar
            </Button>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent id={ariaDescribedby}>{children}</DialogContent>
      {!noActions && size(ButtonsProps) > 0 && <DialogActions></DialogActions>}
    </Dialog>
  );
};

ModalSmg.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  scroll: PropTypes.oneOf(["body", "paper"]),
  TransitionComponent: PropTypes.oneOf(["fade", "slide", "grow", "zoom"]),
  children: PropTypes.node.isRequired,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  closeIconButton: PropTypes.bool,
  noActions: PropTypes.bool,
  onClose: PropTypes.func,
  ButtonsProps: PropTypes.arrayOf(PropTypes.object.isRequired),
  dividers: PropTypes.oneOf(["header", "body", "actions"]),
};

export default ModalSmg;
