import { combineReducers } from 'redux';
import termsConditionsReducer from './termsConditionsReducer'
import adviceReducer from './adviceReducer'
import authLoginReducer from './authLoginReducer';
import userInstanceReducer from './userInstanceReducer';
import debtReducer from './debtReducer';

export default combineReducers({
    termsConditions:termsConditionsReducer,
    advice: adviceReducer,
    authLogin: authLoginReducer,
    userInstance: userInstanceReducer,
    debtReducer: debtReducer
});