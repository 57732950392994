import React ,{useLayoutEffect}  from 'react'
import { useLocation } from 'react-router-dom'
import {titleCelulasMadreMobile,titleCelulasMadre, textCelulasMadreOne,celulasHematopoyeticasTitle,celulasHematopoyeticasText,celulasMesenquimalesTitle,celulasMesenquimalesText,obtienen} from './data';
import './celulasMadre.css'

const CelulasMadre = () => {
    const refCelulasMadre = React.createRef();
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        const location = window.location.pathname.replace("/", "");
        if (location === "banco-celulas-madre") {
            refCelulasMadre.current.scrollIntoView();
          }
    }, [pathname, refCelulasMadre])
    return (
        <div className = "containerCelulasMadre" id="celulasMadres" ref={refCelulasMadre}>
            <h2 className = "titleCelulasMadre">{titleCelulasMadre}</h2>
            <h2 className = "titleCelulasMadreMobile">{titleCelulasMadreMobile}</h2>
            <p className = "textCelulasMadreOne">
                 {textCelulasMadreOne}
            </p>
            <div className = "containerCelulasLogos">
                <div className = "containerCelulasHematopoyecticas">
                    <div> <h2 className='titleTypeCelula'>Células de Sangre</h2></div>
                <div className='circleImage'>
                    <img src = "img/new_hematopoyeticas.png" alt = "Logo Células Hematopoyéticas"/>
                </div>
                    
                    <h3 className = "titleCelulas">{celulasHematopoyeticasTitle}</h3>
                    <p className = "paragraphCelula">{
                        celulasHematopoyeticasText.map( ( element ) => ( 
                            <span className ={element.style === "bold" ? "boldParagraf" : null}>{element.text}</span>
                         ) )
                    }</p>
                </div>
                <div className = "containerLogoGrupo">
                    <img src = "img/new_Grupo25.png" alt = "Logo Grupo" className='imgGrupo'/>
                </div>
                <div className = "containerCelulasMesenquimales">
                <div> <h2 className='titleTypeCelula'>Células de Tejido</h2></div>
                <div className='circleImage'>
                    <img src = "img/new_mesenquimales.png" alt = "Logo Células Hematopoyéticas"/>
                </div>
                    <h3 className = "titleCelulas">{celulasMesenquimalesTitle}</h3>
                    <p className = "paragraphCelula">{celulasMesenquimalesText.map( ( element ) => ( 
                            <span className ={element.style === "bold" ? "boldParagraf" : null}>{element.text}</span>
                         ) )}</p>
                </div>
            </div>
            <div className = "containerObtienen">
                <h2 className = "titleObtienen">{obtienen.title}</h2>
                <p className = "paragraphObtienen">{obtienen.text}</p>
            </div>
        </div>
    )
}

export default CelulasMadre
