import React from "react";
import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";
import {whyChooseOne,whyChooseThree,whyChooseFour, whatsapp} from './data'
import "../PorqueElegirnos/porqueElegirnos.css";
import { WhatsappButton } from "../Whatsapp/WhatsappButton";
import { Link, Typography } from "@material-ui/core";
import ScrollToTopOnMount from "../../utils/scrollHeader";

export default function PorqueElegirnos() {
  const handleSocios = (e) =>{
    e.preventDefault();
    window.open('https://www.swissmedical.com.ar/prepagaclientes', '_blank');
  }

 return(
    <>
   <main>
     
       <Navbar />
    <div className = "container_imagen">
      <img src= 'img/puntos_y_rec.png' alt= "logo puntos y rectangulo"/>   
    </div>   
    <ScrollToTopOnMount />
    <h2 className = "mainTitle">¿POR QUÉ ELEGIRNOS?</h2>
      <div className ="gridText">
        <div className= "gridTextOne">
         <p style={{fontFamily:'Red Hat Display'}}>
            {whyChooseOne.map((element)=>(
                <span className ={element.style === "bold" ? "boldParagraf" : null}>{element.text}</span>
                )
              )
            }
          </p>
        </div>
      <div className = "gridTextTwo">
        <p style={{fontFamily:'Red Hat Display'}}>
          {whyChooseThree.map((element)=>(
               <span className ={element.style === "bold" ? "boldParagraf" : null}>{element.text}</span>
              )
            )
          }
        </p>
      </div>
      <div className = "gridTextThree">
        <p style={{fontFamily:'Red Hat Display'}}>
               <span >Además, contamos con el apoyo y respaldo de los profesionales de nuestros 
               <Typography component='span'> Swiss Medical Center</Typography> , y 
               destacados especialistas que integran la cartilla de <Typography component='span' >
                <Link underline="hover" 
                      style={{color:'#00748b'}}
                       href="#"
                       onClick={handleSocios}
                      >Swiss Medical Medicina Privada
                </Link>.</Typography>
              </span>
        </p>
      </div>
      <div className = "gridTextFour">
        <h3 className = "titleProfessionals">STAFF</h3>
        <ul className = "listprofessionals">
            {whyChooseFour.map((element)=>(
              <li style={{fontFamily:'Red Hat Display'}}>
                  {`${element.textNormal}  `}<span className = "boldParagraf"> {element.textBold}</span> 
              </li> 
                )
              )
            }
        </ul>
      </div>
    </div>     
  </main> 
    <Contact />
    <WhatsappButton src={ whatsapp.src } />
  </>
 )  
}
