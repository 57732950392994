import React from 'react'
import './buttonAsesorame.css'

const ButtonAsesorame = () => {
    const handleButton = ()=>{
        window.scrollTo(0, 0);
    }
    return (
        <div>
            <button type= "submit" className = "btnAsesorame" style={{cursor: 'pointer'}} onClick = {()=>{handleButton()}}> QUIERO ASESORARME</button>
        </div>
    )
}

export default ButtonAsesorame
