import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import {generatePin} from "../../../utils/backendRequest";
import {SITE_KEY} from "../../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import {messageAlert} from "../../../utils/alerts/alerts";
import {useFormikContext} from "formik";

const Timer = ({ delayResend = "120" }) => {
  const { values: formValues } = useFormikContext();
  const recaptchaRef = React.createRef();
  const [delay, setDelay] = useState(+delayResend);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);
  const classes = useStyles();

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  const handlerReenvioCodigo = async () => {
    //regeneración de pin y envío del mismo al usuario.
    recaptchaRef.current?.reset();
    const tokenRc = await recaptchaRef.current.executeAsync();
    if (tokenRc) {
      const data = {
        recaptchaResponse: tokenRc,
        "nombre": formValues.nombre,
        "apellido": formValues.apellido,
        "email": formValues.email
      }
      const generationResendPin = await Promise.all([
        generatePin('/getResendRegistrationPin', data)
      ]);
      if(generationResendPin[0].data.status !== 200) {
        messageAlert('info', 'Aviso', generationResendPin[0].data.message);
        return false;
      }else{
        messageAlert('info', 'Aviso', '¡PIN Reenviado satisfacoriamente! Revisa tu casilla de correo nuevamente.');
        return true;
      }
    }
  }

    return (
        <>
          {minutes === 0 && seconds === 0 ? (
              <Grid item xs>
                <Button
                    className={classes.sendButton}
                    color="primary"
                    variant="outlined"
                    onClick={handlerReenvioCodigo}
                >
            <span className={classes.txtButton}>
              Reenviar código de confirmación
            </span>
                </Button>
              </Grid>
          ) : (
              <>
                <Grid item xs>
                  <Typography variant="p" display="block" gutterBottom>
                    ¿Todavía no lo recibiste?
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="p" display="block" gutterBottom>
                    Volvé a enviar el código en {minutes}:{seconds}
                  </Typography>
                </Grid>
              </>
          )}
          <ReCAPTCHA
              ref={recaptchaRef}
              style={{ marginTop: "5%" }}
              size="invisible"
              sitekey={SITE_KEY}
          />
        </>
    );
};

export default Timer;
