import React from "react";
import OtpInput from "react-otp-input";

import { at } from "lodash";
import { useField } from "formik";
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
} from "@material-ui/core";

function InputOtpField(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        control={
          <OtpInput
            {...field}
            onChange={(code) => {
              setValue(code);
            }}
            numInputs={6}
            inputStyle={{
              fontSize: "32px",
              width: "36px",
              height: "36px",
              margin: "4px",
              borderTop: "0px",
              borderLeft: "0px",
              borderRight: "0px",
              outline: "none",
              borderColor: "#000a46",
              background: "#fafafa",
            }}
            containerStyle={{
              margin: "20px auto",
              padding: "10px",
            }}
            isInputNum
          />
        }
      />
      {_renderHelperText()}
    </FormControl>
  );
}

export default InputOtpField;
