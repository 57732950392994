import React, { useRef } from "react";
//import { useIdleTimer } from "react-idle-timer";
//import authLogOut from "../services/authActions";
//import { useHistory } from "react-router-dom";

const IdleTimer = ({ children }) => {
  //const history = useHistory();

  //var refresh = window.sessionStorage.getItem("refresh");
  //console.log(refresh);

  const handleOnIdle = () => {
    console.log("inactivo");
    //authLogOut();
    //if (refresh === "") {
    sessionStorage.removeItem("nombre");
    sessionStorage.removeItem("apellido");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("docum");
    sessionStorage.removeItem("LOCALSTORAGE_AUTH_API");
    window.location.href = "/";
    //window.sessionStorage.setItem("refresh", "1");
    //}
    //history.push("/");
  };

  const TIME_OUT = 1000 * 10 * 60 * 2;

/*   useIdleTimer({
    timeout: TIME_OUT,
    onIdle: handleOnIdle,
  }); */
  return <div>{children}</div>;
};

export default IdleTimer;
