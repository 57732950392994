import{
    ADVICE_SUCCESS,
    ADVICE_ERROR,
    ADVICE_LOADING,
    ADVICE_RESET
} from '../types'

import { postInfo } from '../utils/backendRequest'

export function  adviceActions (data){
    return async (dispatch)=>{
            dispatch(adviceLoading())
            postInfo('/advice', data, 
            (body)=>{
                dispatch(adviceSuccess(body.data))
            },
            (err)=>{
                err.response ? 
                dispatch(adviceError(err.response.data.message)) : 
                dispatch(adviceError('Estamos trabajando para resolver este inconveniente cuanto antes. Por favor recargue la página e intente nuevamente.')) 
            })
            //const response = await clientAxios.post('/api/advice', data) 
    }
}

const adviceLoading =()=>({
    type: ADVICE_LOADING,
    payload:true
})

const adviceSuccess =(data)=>({
    type: ADVICE_SUCCESS,
    payload:data
})

const adviceError=(error)=>({
    type:ADVICE_ERROR,
    payload:error
})

export const adviceReset =()=>({
    type:ADVICE_RESET, 
})