import request from "request";
import { merge } from "lodash";
import moment from "moment";
//import history from '../history';

import getToken from "./getToken";
import { LOCALSTORAGE_AUTH_KEY } from '../constants';
import getBrowser from "./getBrowser";
import getGuid from "./getGuid";
//import redirectTo from './redirectTo';
//import getEnvConfig from '../getEnvConfig';

const API_MANAGER = window.REACT_APP_API_MANAGER;

const SGI = window.SGI;

function getDefaultOptions() {
  const token = getToken();

  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
}

function handleExpiration() {
  sessionStorage.clear();
  //const currentLocation = history.location.pathname;
  //redirectTo(`/login?action=expired&redirectTo=${currentLocation}`, true);
}

let refreshingToken = false;
function checkExpirationFirst(executeApiCall) {
  try {
    const auth = JSON.parse(sessionStorage.getItem(LOCALSTORAGE_AUTH_KEY));
    const { exp } = auth;
    const expiration = moment(exp, "YYYYMMDDHHmmss");
    const now = moment(new Date());
    const minutesToExpire = expiration.diff(now, "minutes");
    if (minutesToExpire <= 0) {
      handleExpiration();
    } else if (minutesToExpire < 10 && !refreshingToken) {
      const ipUser = getGuid();
      refreshingToken = true;
      apiManagerRequest(
        "/v0/auth-refresh",
        {
          method: "POST",
          json: {
            device: {
              bloqueado: true,
              recordar: true,
              deviceid: ipUser,
              messagingid: ipUser,
              devicename: getBrowser(),
            },
          },
        },
        (res) => {
          const { exp: newExp, token: newToken } = res;
          const newAuth = { ...auth, exp: newExp, token: newToken };
          sessionStorage.setItem(LOCALSTORAGE_AUTH_KEY, JSON.stringify(newAuth));
          refreshingToken = false;
          executeApiCall();
        },
        () => {
          refreshingToken = false;
          executeApiCall();
        }
      );
    } else {
      executeApiCall();
    }
  } catch (e) {
    executeApiCall();
  }
}

export const apiManagerRequest = (
  uri,
  options,
  successCallback,
  failCallback
) => {
  checkExpirationFirst(() => {
    const defaultOptions = getDefaultOptions();
    const requestOptions = merge(defaultOptions, options || {});
    requestOptions.uri = `${API_MANAGER}${uri}`;
    request(requestOptions, (err, rsp, body) => {
      if (rsp && rsp.statusCode >= 200 && rsp.statusCode < 300) {
        if (typeof successCallback === "function") {
          successCallback(body, rsp);
        }
      } else if (typeof failCallback === "function") {
        failCallback(rsp, err);
      }
    });
  });
};

export const apiSgiRequest = (uri, options, successCallback, failCallback) => {
  checkExpirationFirst(() => {
    const defaultOptions = getDefaultOptions();
    const requestOptions = merge(defaultOptions, options || {});
    requestOptions.uri = `${SGI}${uri}`;
    request(requestOptions, (err, rsp, body) => {
      if (rsp && rsp.statusCode >= 200 && rsp.statusCode < 300) {
        if (typeof successCallback === "function") {
          successCallback(body, rsp);
        }
      } else if (typeof failCallback === "function") {
        failCallback(rsp, err);
      }
    });
  });
};

export const apiManagerPromise = (uri, options) => {
  return new Promise((resolve, reject) => {
    apiManagerRequest(
      uri,
      options,
      (res) => {
        try {
          resolve(JSON.parse(res));
        } catch (e) {
          resolve(res);
        }
      },
      (res) => reject(res)
    );
  });
};
/*
export const apiManagerFetch = (
  uri,
  options,
  successCallback,
  failCallback,
) => {
  checkExpirationFirst(() => {
    const defaultOptions = getDefaultOptions();
    const requestOptions = merge(defaultOptions, options || {});
    fetch(`${API_MANAGER}${uri}`, requestOptions).then(
      res => {
        if (res.status >= 200 && res.status < 300) {
          if (typeof successCallback === 'function') {
            successCallback(res);
          }
          // Faltaria responder ante token vencido.
        } else if (typeof failCallback === 'function') {
          failCallback(res);
        }
      },
      res => {
        if (typeof failCallback === 'function') {
          failCallback(res);
        }
      },
    );
  });
};*/
