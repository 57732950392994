
import React from 'react';
import { Link } from 'react-router-dom';
import './whatsapp.css';
import { WHATSAPP_URL } from "../../constants/index";

export const WhatsappButton = ( { src } ) => {

  const handleAsesoria = () => {
    window.location.href = `${WHATSAPP_URL}`;
  }

  return (
    <div className="btn-whatsapp">
        <Link 
          onClick={() => {
            handleAsesoria();
          }}
        >
          <img src={ `${src}` } alt="Whatsapp"/>
        </Link>
        
    </div>
  )
}
