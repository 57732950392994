import React from "react";
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";

import { at } from "lodash";
import { useField } from "formik";

const data = [
  {
    id: "M",
    value: "Masculino",
  },
  {
    id: "F",
    value: "Femenino",
  },
];

export default function RadioField(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.value);
  }

  return (
    <FormControl margin="normal" variant="outlined" {...rest}>
      <FormLabel>Género</FormLabel>
      <RadioGroup row onChange={_onChange} {...field}>
        {data.map((datum) => (
          <FormControlLabel
            label={datum.value}
            key={datum.id}
            value={datum.id}
            control={
              <Radio
                //size="medium"
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                  },
                }}
              />
            }
          />
        ))}
      </RadioGroup>
      {_renderHelperText()}
    </FormControl>
  );
}
