import React, {useState} from 'react'
import {dataBeneficiosCriopreservar} from './data'
import ButtonAsesorame from '../ButtonAsesorame/ButtonAsesorame'
import './beneficosCriopreservar.css'

const BeneficiosCriopreservar = () => {
    const [showAplicacionDetalle,setShowAplicacionDetalle] = useState(false)
    const [showProcesoDetalle,setShowProcesoDetalle] = useState(false)

    const handleAplicacionDetalle =()=>{
        setShowAplicacionDetalle(!showAplicacionDetalle)
    }

    const handleProcesoDetalle =()=>{
        setShowProcesoDetalle(!showProcesoDetalle)
    }
    return (
        <><div className="containerBeneficiosCriopreservar">
            <div className = "ContainerFondoPagina" >
                <div className='containerLogosFondo'> 
                    <img className='imgFondoFlor'src = "img/new_Grupo2457.png" alt = "Logo fondo flor" />
                    <img className = "imgFondoOvalo" src = "img/new_logo_fondo.png" alt = "Logo fondo página" />   
                    <img className='imgFondoFlorFondo'src = "img/new_Grupo2457.png" alt = "Logo fondo flor" />
                </div>
                 
            </div> 
            
            <h2 className="titleBeneficios">{dataBeneficiosCriopreservar.titleBeneficios}</h2>
            <div className="containerParagraphBeneficios">
                <p className="paragraphBeneficios">{dataBeneficiosCriopreservar.paragraph}</p>
            </div>
            <div className=" containerListCrio">
                <div className="containerListAplicacion">
                    <img className = "logoMedicoAplicacion" src="img/medico-con-familia-mdpi.png" alt="medico-con-familia" />
                    <div className = "containerSubtitleAplicacion">
                        <h3 className = "subtitleAplicacion">
                            {dataBeneficiosCriopreservar.subtitleAplicacion.map((title, index) => {
                            return title.type === "bold" ? <span className = "subtitleAplicacionBold"  key={index}> {title.title}</span>: <span key={index}> {title.title}</span>
                            })}
                        </h3>
                    </div>
                    <div className = "containerSubtitleAplicacionMobile" onClick ={()=>{handleAplicacionDetalle()}}>
                    <h3 className = "subtitleAplicacionMobile">{dataBeneficiosCriopreservar.subtitleAplicacionMobile}</h3>
                    <img className = {!showAplicacionDetalle ? `rowDownBMedico showAplicacionDetalleMobile` : `rowDownBMedico hideAplicacionDetalleMobile`} src = "img/Componentef.png" alt = "Flecha hacia abajo"/>
                    <img  className = {!showAplicacionDetalle ? `rowUpBMedico hideAplicacionDetalleMobile` : `rowUpBMedico showAplicacionDetalleMobile`}  src = "img/flecha-arriba-mdpi.png" alt = "Flecha hacia arriba"/>
                    </div>
                    <div className = "containerlistDetalles">  
                        <ul className = {!showAplicacionDetalle ? " listBeneficiosCriopreservar hideAplicacionDetalleMobile" : "listBeneficiosCriopreservar showAplicacionDetalleMobile"}>
                            {dataBeneficiosCriopreservar.listAplicacion.map((aplicacion) => {
                                return <li  key={aplicacion.text}>
                                    <span className = "listDetalles">{aplicacion.text}</span>
                                </li>
                            })}
                        </ul>
                    </div>
            </div>
            <div className="containerListProceso">
                <img className = "logoCientificoProceso" src="img/cientifico-mdpi.png" alt="cientifico" />
                <div className = "containerSubtitleProceso">
                        <h3 className = "subtitleProceso">
                            {dataBeneficiosCriopreservar.subtitleProceso.map((title,index) => {
                            return title.type === "bold" ? <span key={index} className = "subtitleProcesoBold "> {title.title}</span>: <span key={index}> {title.title}</span>
                            })}
                        </h3>
                 </div>
                 <div className = "containerSubtitleAplicacionMobile" onClick ={()=>{handleProcesoDetalle()}}>
                    <h3 className = "subtitleAplicacionMobileCientifico">{dataBeneficiosCriopreservar.subtitleAplicacionMobile}</h3>
                    <img className = {!showProcesoDetalle ? `rowDownB showAplicacionDetalleMobile` : `rowDownB hideAplicacionDetalleMobile`} src = "img/Componentef.png" alt = "Flecha hacia abajo"/>
                    <img  className = {!showProcesoDetalle ? `rowUpB hideAplicacionDetalleMobile` : `rowUpB showAplicacionDetalleMobile`}  src = "img/flecha-arriba-mdpi.png" alt = "Flecha hacia arriba"/>
                 </div>
                 <div className = "containerlistDetalles">
                    <ul className = {!showProcesoDetalle ? " listBeneficiosCriopreservar hideAplicacionDetalleMobile" : "listBeneficiosCriopreservar showAplicacionDetalleMobile"}>
                        {dataBeneficiosCriopreservar.listProceso.map((aplicacion,index) => {
                            return <li key={index}>
                            <span className = "listDetalles"> {aplicacion.text}</span>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        </div>
            <ButtonAsesorame />
        </div>
        </>
    )
}

export default BeneficiosCriopreservar
