import React from 'react'
import {useField} from 'formik';
import './styles.css';

const Number = ({widthInput,mright,...props}) => {
    const [field,meta] = useField({...props,type:"number"})
    return (
        <div className = "containerInput">
             <input className = "inputNumber input" type="number" {...field} {...props} style = {{width :`${widthInput}px`, marginRight:`${mright}px`}}/>
            {meta.touched && meta.error ? <p className = "errors">{meta.error}</p>:null}
        </div>
    )
}

export default Number
