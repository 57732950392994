import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    breakpoints: {
    values: {
      xs:0,
      sm: 0,
      md: 768,
      lg: 992,
      xl: 1280
    },
  },
})

export default theme