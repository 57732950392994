import React from 'react'
import {cyclicLineTitle,cyclicLineDetalle,cyclicLineParagraph,condiciones ,cyclicLineDetalleMobile} from './data'
import './cyclicLine.css'
import Carousel from 'react-material-ui-carousel';

const CyclicLine = () =>{
    return (
        <div className = "containerCyclicLine">
           <h2 className = "titleClicLine">{cyclicLineTitle}</h2>
             <div className = "containerCyclicLineDetalleMap">
                {cyclicLineDetalle.map(cyclic=>{
                    return (
         
                        <div className = "containerCyclicLineDetalles" key = {cyclic.id}>
                            <img className='ImgIconsCyclic'  src = {cyclic.src} alt = "Imagen Cyclic Line "/>
                            <div className = "containerTitleText">
                                <h3 className = "titleDetails">{cyclic.title}</h3>
                                <p className = "paragraphDetails">{cyclic.text}</p>
                            </div>
                            
                        </div>
                    
                    )
                })}
            </div>

             <div className = "containerSlider">
                <Carousel animation="slide">
                    {cyclicLineDetalleMobile.map(cyclic=>{
                        return (
                            <div className = "containerCyclicLineDetallesMobile" key = {cyclic.id}>
                                <img className = "imgCyclicLineDetallesMobile" src = {cyclic.src} alt = "Imagen Cyclic Line "/>
                                <div className = "containerTitleTextMobile">
                                    <span className = "titleDetailsMobile">{cyclic.title}</span>
                                    <span className = "paragraphDetailsMobile">{cyclic.text}</span>
                                </div>
                                
                            </div>
                        )
                    })} 
                </Carousel>
            </div>
           <span className = "cyclicLineParagraph">{cyclicLineParagraph}</span>
            <span className = "condiciones">{condiciones}</span>
        </div>
    )
}

export default CyclicLine
