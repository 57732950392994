import React from 'react'
import {useField} from 'formik';
import './styles.css';

const Date = ({ label,...props}) => {
    const [field,meta] = useField({...props,type:"date"})
    return (
        <div className = "containerInput">
        <label className ="labelDate">{label}</label>
        <div className = "containerDate">
            <input className = "inputDate input" type="date" {...field} {...props} />
            {meta.touched && meta.error ? <p className = "errorsDate">{meta.error}</p>:null}
        </div>
        </div>
    )
}

export default Date
