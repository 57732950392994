import React from 'react'
import {useField} from 'formik';
import './styles.css';

const Email = ({placeholder,...props}) => {
    const [field,meta] = useField({...props,type:"email"})
    return (
        <div className = "containerInput">
            <input className = "inputEmail input" type="email" placeholder = {placeholder} {...field} {...props}/>
            {meta.touched && meta.error ? <p className = "errors">{meta.error}</p>:null}
        </div>
    )
}

export default Email
