import store from "../store"
import { removeInstance, updateInstance } from "../reducers/userInstanceReducer";


export function createUserInstance(body, modelEspecificoUser) {
    const {token, expiresIn, userToken} = body;
    const { nombre, apellido, docu_tipo, docu_nro, id_user } = body.modelEspecificoUser;
    const CURRENT_USER = {
        nombre,
        apellido,
        token,
        userToken,
        expiresIn,
        docu_tipo,
        docu_nro,
        id_user
    }
    //Se necesita que persista la info básica al refrescar la página
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(CURRENT_USER))

    store.dispatch(updateInstance(CURRENT_USER))

}

function removeUserInstance() {
    sessionStorage.removeItem("CURRENT_USER");
    store.dispatch(removeInstance());
}

export function getAMToken() {
    if(sessionStorage.getItem("CURRENT_USER")) {
        const CURRENT_USER = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
        store.dispatch(updateInstance(CURRENT_USER));
    }

    return store.getState().userInstance.token
}

export function getUserToken() {
    if(sessionStorage.getItem("CURRENT_USER")) {
        const CURRENT_USER = JSON.parse(sessionStorage.getItem("CURRENT_USER"));
        store.dispatch(updateInstance(CURRENT_USER));
    }

    return store.getState().userInstance.userToken
}

export function checkTokenExpiration(requestFunction) {
    let now = new Date().getTime();
    let tokenExpiration = store.getState().userInstance.expiresIn;

    if(!tokenExpiration){
        requestFunction();
    } else {
        if(now <= tokenExpiration) {
            requestFunction();
        } else {
            console.log("TOKEN EXPIRADO");
            removeUserInstance();
        }
    }
}