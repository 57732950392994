import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    fontSize: theme.typography.pxToRem(25),

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
});

const ErrorGetBalance = ({ classes, ...other }) => (
  <Typography className={classes.root} align="center" {...other}>
    ¡Ups! En este momento no podemos visualizar el saldo de tu cuenta,{' '}
    <b>de igual manera podés realizar tu pago.</b>
  </Typography>
);

ErrorGetBalance.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ErrorGetBalance);
