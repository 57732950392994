import { useEffect } from "react";

/**
 * cuando se pasa de seccion en seccion se retorna
 * la vista arriba de todo
 * @returns 
 */
const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null; // No renderiza ningún contenido adicional
  };

export default ScrollToTopOnMount;