import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Inicio from "./components/Inicio/Inicio";
import Ingresar from "./components/Ingresar/Ingresar";
import Contratos from "./components/Contratos/Contratos";
import Pagos from "./components/Pagos/Pagos";
import PreguntasFrecuentes from "./components/PreguntasFrecuentes/PreguntasFrecuentes";
import PorqueElegirnos from "./components/PorqueElegirnos/PorqueElegirnos";
import Registrarse from "./components/Registrarse/Registrarse";
import RecuperarPassword from "./components/RecuperarPassword/RecuperarPassword";
import { lineaDenuncias } from "./components/lineaDenuncias/lineaDenuncias";
import { formasDePago } from "./components/formasDePago/formasDePago";
import { Provider } from "react-redux";
import store from "./store";

import IdleTimer from "./utils/IdleTimer";
import { ProtectedRoute } from "./utils";


export class App extends Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <BrowserRouter forceRefresh={false}>
            <IdleTimer>
              <Switch>
                <Route exact path="/login-clientes" component={Ingresar} />
                <Route exact path="/" component={Inicio} />
                <ProtectedRoute
                  exact
                  path="/banco-celulas-madre"
                  component={Inicio}
                />
                <Route exact path="/servicios-smg-cells" component={Inicio} />
                <Route
                  exact
                  path="/preguntas-frecuentes-celulas-madre"
                  component={PreguntasFrecuentes}
                />
                <Route
                  exact
                  path="/lineaDenuncias"
                  component={lineaDenuncias}
                />
                <Route exact path="/formasDePago" component={formasDePago} />
                <Route
                  exact
                  path="/por-que-elegirnos"
                  component={PorqueElegirnos}
                />
                <Route
                  exact
                  path="/registrar-nuevo-clientes"
                  component={Registrarse}
                />
                <Route
                  exact
                  path="/recuperar-clave"
                  component={RecuperarPassword}
                />
                <ProtectedRoute exact path="/pagos" component={Pagos} />
                <ProtectedRoute exact path="/contratos" component={Contratos} />
              </Switch>
            </IdleTimer>
          </BrowserRouter>
        </Provider>
      </div>
    );
  }
}

export default App;
