import React from "react";
import { Redirect, Route } from "react-router";
import { getUserToken } from "./userManager";

import { LOCALSTORAGE_AUTH_API } from "../constants";

const getLocalAccessToken = () => {
  //const token = sessionStorage.getItem("token");
  const token = getUserToken();
  return token;
};

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = getLocalAccessToken();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return (
            <div className="layout-main-container">
              <div className="layout-main">
                <Component {...props} />
              </div>
            </div>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
