import {
  makeStyles,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

let theme = createTheme({});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2),
  },
  avatar: {
    backgroundColor: "#EFEFEF",
    color: "#C7C7C7",
    width: "120px",
    height: "120px",
    marginBottom: "40px",
    marginTop: "30px",
  },
  menuText: {
    marginBottom: 18,
    marginTop: 28,
  },
  paper: {
    padding: "30px",
    margin: "30px auto",
  },
  icon: {
    lineHeight: "200px",
  },
  phone: {
    margin: "20px",
    fontSize: "72px !important",
  },
  sendButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#fafafa",
    //margin: "0",
    width: "200px",
    height: "40px",
    color: "#797979",
    fontSize: "13px",
    fontWeight: "bold",
    borderColor: "#797979",
    borderRadius: "25px",
    textTransform: "capitalize",
    fontFamily: 'Red Hat Display',
    letterSpacing: "0px",
    //opacity: 1,
  },
}));

export { theme, useStyles };
