/* eslint-disable import/prefer-default-export */
import { gt } from 'lodash';

export * from './cardsTypes';
export * from './formatter';
export * from './dataPayment';
export * from './getErrorExecutePayment';

export const hasError = (error, touched) => Boolean(error) && Boolean(touched);

export const getHelperTextError = (error, touched) =>
  hasError(error, touched) ? error : '';

export const paymentIsGreaterThanTheBalance = (pay, balance) =>
  gt(pay, balance);
