import React, { useState } from 'react';
import { Divider, Grid, Box } from "@material-ui/core";
import { Formik, Form } from "formik";
import ContractItem from "./ContractItem";
import { makeStyles } from "@material-ui/core/styles";
import "../contratos.css";
import Modal from "../../../utils/Modal";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../../config/muiTheme';
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { setContract } from "../../../reducers/debtReducer";
import Loading from "../../../utils/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDivider-middle": {
      backgroundColor: "00748b",
      border: "1px solid #00748b",
    }
  }
}));



const ContractBox = (props) => {
    const loadingTermsAndConditions = useSelector(state=>state.termsConditions.loading);
    const { contratos, goPagos } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    const [open, setOpen] = useState(false);

    const handleClose = (e) => {
        setOpen(false);
    };

    const [values, setValues] = React.useState({
        showPassword: false,
        show: false,
        isOpen: false,
        isOpenLoading: false,
        messageModal: "",
        cardType: undefined,
        filterErrors: {},
    });

    //Loading
    const handleOpenLoading = () => {
        setValues({ ...values, isOpenLoading: true });
    };

    const handleCloseLoading = () => {
        setValues({ ...values, isOpenLoading: false });
    };

    const text = useSelector(
        (state) =>
          state.termsConditions.termsConditions &&
          state.termsConditions.termsConditions
      );

    return (
        <>
            { loadingTermsAndConditions && <Loading
                isOpenLoading={values.isOpenLoading}
                handleCloseLoading={handleCloseLoading}/>
            }
            <ThemeProvider theme={theme}>
            <div className={classes.root}>
                {/* >991 show */}
                    <Modal
                    open={open}
                    maxWidth="lg"
                    closeIconButton
                    title={open ? text : null}
                    onClose={handleClose}
                    disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    />
                <div id="pagoOnline" style={{ width: "100%",marginTop: '83px' }}>
                <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4}>
                    <Grid item xs md={7}>
                    <h3>TRAMITE A PAGAR</h3>
                    <Divider variant="middle" className="divider" />
                    <br /> <br />
                    <Formik
                        initialValues={{
                        picked: "",
                        }}
                        onSubmit={async (values) => {
                        await sleep(500);
                        let nroContrato = JSON.stringify(
                            parseInt(values.picked, null, 2)
                        );
                        dispatch(setContract(nroContrato));
                        sessionStorage.setItem('contractSelected',nroContrato)
                        goPagos();
                        }}
                    >
                        {() => (
                        <Form>
                            <div id="my-radio-group"></div>
                            {   
                                contratos.length ?
                                contratos.map((obj, id) => {
                                    const {nombre, apellido, contra} = obj;
                                    return <ContractItem nombre={nombre} apellido={apellido} contra={contra} id={id} />
                                })
                                :
                                    <h5
                                        style={{
                                            left: "30px",
                                            bottom: "12px",
                                            position: "relative",
                                            color: "#707070",
                                            textAlign: "left",
                                            letterSpacing: "var(--unnamed-character-spacing-0)",
                                            fontFamily: 'Red Hat Display',
                                            lineHeight: "25px",
                                            fontSize: "22px !important",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        No se han encontrado trámites para su usuario.
                                    </h5>
                            }
                            <br /> <br />
                            <Divider variant="middle" className="divider" />
                            <br />
                            <br />
                            <button
                            type="submit"
                            id="choose"
                            disabled="disabled"
                            style={{
                                position: "relative",
                                width: "350px",
                                height: "70px",
                                left: "200px",
                                backgroundColor: "#00748b",
                                borderRadius: "30px",
                                color: "#ffff",
                                fontFamily: 'Red Hat Display',
                                fontSize: "24px",
                                outline: "none",
                            }}
                            >
                            CONTINUAR
                            </button>
                        </Form>
                        )}
                    </Formik>
                    </Grid>
                </Box>
            </div>

            <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" bgcolor="#ffff" p={4} md={8} xl={8}>
                <Grid item xs={12} md={8} lg={6}></Grid>
            </Box>
            </div>

            </div>

            </ThemeProvider>
        </>
    )
}

export default ContractBox;