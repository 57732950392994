import React from "react";
import "../Contact/contact.css";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <footer className = "footer">
      <div className = "footerLeft">
        <h3>SMG CELLS</h3>
          <div className = "footerLeftLink">
            <Link
              to="formasDePago"
              className= "link"
            >
              FORMAS DE PAGO
            </Link>
            <Link
              to="preguntas-frecuentes-celulas-madre"
              className= "link"
            >
              PREGUNTAS FRECUENTES
            </Link>
            <Link
              to="lineaDenuncias"
              className= "link"
            >
              LÍNEA DE DENUNCIAS
            </Link>
          </div>
      </div>
      <div className = "footerLineCenter"></div>
      <div className = "footerRight">
        <h3>CONTACTANOS</h3>
          <p>
            Av. Callao 1190 PB, CABA
          </p>
          <p >
            smgcells@swissmedical.com.ar
          </p>
          <p>
              0800 777 7876
          </p>
          <p>
              011 5300 7876
          </p>

      </div>

    </footer> 
 );
}
