import React from "react";
import PropTypes from "prop-types";
import { withStyles, FormControl, FormGroup, Radio } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { cardTypes } from "../utils";

const styles = (theme) => ({
  labelRoot: {
    padding: "0",
    fontSize: "13px",
    fontfamily: 'Red Hat Display',
    fontWeight: "Bold",
    color: "#707070",
    lineHeight: "40px",
  },
  image: {
    width: 45,
    height: 26,
    marginLeft: "-10px",
    marginRight: "8px",
  },
  formGroup: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  fullWidth: {
    width: "100%",
  },
  boxCard: {
    border: "1px solid #CECECE",
    borderRadius: "5px",
    margin: "0px",
    padding: "0px",
    marginBottom: "0.5em",
    width: "93px",
    height: "44px",
  },
  radioB: {
    margin: "0px",
  },
});

const SelectorCard = ({ classes, selectedValue, handleChange, name }) => {
  return (
    <FormControl component="fieldset" className={classes.fullWidth}>
      <Form.Label className={classes.labelRoot}>
        Seleccioná el tipo de tarjeta
      </Form.Label>
      <FormGroup row className={classes.formGroup}>
        {cardTypes.map((card) => (
          <div className={classes.boxCard} key={card.type}>
            <Radio
              style={{ height: "42px" }}
              color="primary"
              checked={selectedValue === card.type}
              onChange={handleChange}
              value={card.type}
              name={name}
            />
            <img
              src={card.imageLogo}
              alt={`${card.name} Logo`}
              className={classes.image}
            />
          </div>
        ))}
      </FormGroup>
    </FormControl>
  );
};

SelectorCard.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  selectedValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default withStyles(styles)(SelectorCard);
