export default {
  formId: "checkForm",
  formField: {
    nombre: {
      name: "nombre",
      label: "Nombre*",
      requiredErrorMsg: "Es requerido",
    },
    apellido: {
      name: "apellido",
      label: "Apellido*",
      requiredErrorMsg: "Es requerido",
    },
    documento: {
      name: "documento",
      label: "Número de documento*",
      requiredErrorMsg: "Es requerido",
    },
    tipo: {
      name: "tipo",
      label: "Tipo*",
      requiredErrorMsg: "Es requerido",
    },
    genero: {
      name: "genero",
      label: "Género*",
      requiredErrorMsg: "Es requerido",
    },
    email: {
      name: "email",
      label: "Email*",
      requiredErrorMsg: "Es requerido",
      invalidErrorMsg: "No es válido",
    },
    confirmEmail: {
      name: "confirmEmail",
      label: "Confirmar Email*",
      requiredErrorMsg: "Es requerido",
      invalidErrorMsg: "No es válido",
    },
    clave: {
      name: "clave",
      label: "Clave*",
      requiredErrorMsg: "Es requerido",
    },
    fecha: {
      name: "fecha",
      label: "Fecha de nacimiento*",
      requiredErrorMsg: "Es requerido",
      invalidErrorMsg: "No es válida",
    },
    code: {
      name: "code",
      label: "Code*",
      requiredErrorMsg: "Es requerido",
      invalidErrorMsg: "No es válido",
    },
    contrato: {
      name: "contrato",
      label: "Contrato*",
      requiredErrorMsg: "Es requerido",
      invalidErrorMsg: "No es válido",
    },
    terms: {
      name: "terms",
      label: "Acepto los términos y condiciones.",
      requiredErrorMsg: "Es requerido",
      invalidErrorMsg: "No es válido",
    },
  },
};
