import React,{useLayoutEffect} from 'react'
import { useLocation } from 'react-router-dom'
import {servicios} from './data'
import './servicios.css'
import TablaServicios from './TablaServicios/TablaServicios'
import ButtonAsesorame from '../ButtonAsesorame/ButtonAsesorame'

const Servicos = () => {
    const refServicios = React.createRef();
    const { pathname } = useLocation();
    useLayoutEffect(() => {
        const location = window.location.pathname.replace("/", "");
    if (location === "servicios-smg-cells") {
      refServicios.current.scrollIntoView();
    } 
    }, [pathname, refServicios])
    return (
        <div className = "containerServicios" id="servicios" ref={refServicios}>
            <div className = "containerServiceDetalle">
                <img className='imageFlor' src = "img/new_Grupo2380.png" alt = "Flor"/>
                <h2 className= "titleServicios">{servicios.title}</h2>  
                <p className = "paragraphServicios">{servicios.textOne}</p>
                <p className = "paragraphServicios">{servicios.textTwo}</p>
            </div>
            <TablaServicios/>
            <div className='btnAsersorar'>
            <ButtonAsesorame/>
            </div>
        </div>
    )
}

export default Servicos
