import React from "react";
import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";
import {frequentlyQuestions, whatsapp} from './data';
import "../PreguntasFrecuentes/preguntasFrecuentes.css";
import PreguntasFrecuentesCard from "../PreguntasFrecuentesCard/PreguntasFrecuentesCard";
import { WhatsappButton } from "../Whatsapp/WhatsappButton";
import ScrollToTopOnMount from "../../utils/scrollHeader";

export default function PreguntasFrecuentes() {
  const handleFlechaUp =()=>{
    window.scrollTo(0, 0);
  }
  return (
    <>
 <Navbar />
 <ScrollToTopOnMount />
    <main className = "container_frequently_question">
    <div className="containerAll">
        <div>
          <h2 className = "title_container">PREGUNTAS FRECUENTES</h2>
        </div>
       <div className = "frequently_question">
      <div className = "container_img_woman">
        <img class = "img_mujer" src="img/mujer.png" alt="Logo mujer" />
        <img class = "img_fondo" src="img/Grupo2491.png" alt="Logo mujer" />
      </div>
      
        {
          frequentlyQuestions.map((question)=>{
            return (
                  <div className = {question.class}>
                    <PreguntasFrecuentesCard key={question.id} question = {question} class = {question.class}/>
                 </div>)
          }
          )
        }
      </div>
        <div className = "arrow_up" onClick = {()=>{handleFlechaUp()}}>
        <img src="img/flecha-arriba-mdpi.png" alt = "flecha up" />
      </div> 
      </div>
    </main>  
    <Contact />
    <WhatsappButton src={ whatsapp.src } />
    </>
  );
}
