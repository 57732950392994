import VisaLogo from '../images/visa_logo.png';
import VisaDebitoLogo from '../images/visa_debito.png';
import MastercardLogo from '../images/mastercard_logo.svg';
import DinnersClubLogo from '../images/dinners_club_logo.svg';
import AmericanExpressLogo from '../images/american_express_logo.svg';
import MaestroPrismaLogo from '../images/maestro_logo.png';
import CabalLogo from '../images/cabal_logo.png';

export const DEFAUTL_CARD_FORMAT = /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?/;
export const DEFAULT_CARD_LENGTH = 16;

export const DEFAULT_CARD_LENGTH_MAESTRO = 18;
export const DEFAULT_CARD_LENGTH_AMEX = 15;
export const DEFAULT_CODE_SECURITY_LENGTH = 3;
export const CODE_SECURITY_LENGTH_AMEX = 4;

export const cardTypes = [
  {
    id: 31,
    name: 'VISA Debito',
    type: 'visa-debito',
    format: DEFAUTL_CARD_FORMAT,
    startPattern: /^4/, // este es parecido al de visa normal no se modifico porque se trabajo de otra manera
    length: DEFAULT_CARD_LENGTH,
    codeSecurity: {
      name: 'CVV',
      length: DEFAULT_CODE_SECURITY_LENGTH,
    },
    imageLogo: VisaDebitoLogo,
  },
  {
    id: 1,
    name: 'VISA',
    type: 'visa',
    format: DEFAUTL_CARD_FORMAT,
    startPattern: /^4/,
    length: DEFAULT_CARD_LENGTH,
    codeSecurity: {
      name: 'CVV',
      length: DEFAULT_CODE_SECURITY_LENGTH,
    },
    imageLogo: VisaLogo,
  },
  {
    id: 104,
    name: 'Mastercard',
    type: 'mastercard',
    format: DEFAUTL_CARD_FORMAT,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    length: DEFAULT_CARD_LENGTH,
    codeSecurity: {
      name: 'CVC',
      length: DEFAULT_CODE_SECURITY_LENGTH,
    },
    imageLogo: MastercardLogo,
  },
  {
    id: 106, //Documentación en https://decidirv2.api-docs.io/1.0/tablas-de-referencia-e-informacion-para-el-implementador/medios-de-pago-disponibles
    name: 'Maestro',
    type: 'maestro',
    format: DEFAUTL_CARD_FORMAT,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    length: DEFAULT_CARD_LENGTH_MAESTRO,
    codeSecurity: {
      name: 'CVC',
      length: DEFAULT_CODE_SECURITY_LENGTH,
    },
    imageLogo: MaestroPrismaLogo,
  },
  {
    id: 65,
    name: 'American Express',
    type: 'amex',
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    startPattern: /^3[47]/,
    length: DEFAULT_CARD_LENGTH_AMEX,
    codeSecurity: {
      name: 'CID',
      length: CODE_SECURITY_LENGTH_AMEX,
    },
    imageLogo: AmericanExpressLogo,
  },
  {
    id: 63,
    name: 'Cabal Prisma',
    type: 'cabalprisma',
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    startPattern: /^(36|38|30[0-5])/,
    length: DEFAULT_CARD_LENGTH,
    codeSecurity: {
      name: 'CVV',
      length: DEFAULT_CODE_SECURITY_LENGTH,
    },
    imageLogo: CabalLogo,
  },
  {
    id: 8,
    name: 'Diners Club',
    type: 'dinersclub',
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    startPattern: /^(36|38|30[0-5])/,
    length: DEFAULT_CARD_LENGTH,
    codeSecurity: {
      name: 'CVV',
      length: DEFAULT_CODE_SECURITY_LENGTH,
    },
    imageLogo: DinnersClubLogo,
  },
];

export const getCardTypeByValue = value =>
  cardTypes.filter(cardType => cardType.startPattern.test(value))[0];

export const getCardTypeByType = type =>
  cardTypes.filter(cardType => cardType.type === type)[0];

export const getCardTypeById = value =>
  cardTypes.filter(cardType => cardType.id === value)[0];
