import NavbarDesktop from "./components/NavbarDesktop";
import NavbarMobile from "./components/NavbarMobile";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../config/muiTheme';
import "./navbar.css";


const Navbar = () => {


  const userDni = useSelector((state)=> state.userInstance.docu_nro);
  const [isLogged, setIsLogged] = useState(false);

  useEffect(()=>{
    setIsLogged(!!userDni)
  },[userDni])

  return (
    <>
      <ThemeProvider theme={theme}>
        <NavbarDesktop isLogged={isLogged}/>
        <NavbarMobile isLogged={isLogged}/>
      </ThemeProvider>
    </>
  )
}

export default Navbar;