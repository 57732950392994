import { useField } from 'formik'
import React from 'react'
import './styles.css';
const TextInput = ({mright,mleft,...props}) => {
    const [field,meta]= useField({...props , type:"text"});
    return (
        <div className = "containerInput">
            <input type = "text" className = "inputText input"{...field} {...props} style = {{ marginRight:`${mright}px`, marginLeft:`${mleft}px`} }/>
            {meta.touched && meta.error? <p className = "errors">{meta.error}</p> : null}
        </div>
    )
}
export default TextInput