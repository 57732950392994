import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, InputAdornment } from '@material-ui/core';

import calendarLogo from '../images/calendar_logo.svg';
import cvvLogo from '../images/cvv_logo.svg';
import numberDocumentLogo from '../images/number_document_logo.svg';
import typeDocumentLogo from '../images/type_document_logo.svg';
import userLogo from '../images/user_logo.svg';
import cardLogo from '../images/card_logo.svg';

const styles = () => ({
  adornment: {
    margin: 'auto 10px',
  },
  logo: {
    width: 20,
    height: 20,
  },
});

const images = {
  caledar: calendarLogo,
  cvv: cvvLogo,
  number: numberDocumentLogo,
  typeDoc: typeDocumentLogo,
  user: userLogo,
  card: cardLogo,
};

const AdornmentInputs = ({ classes, type, alt }) => (
  <InputAdornment position="start" className={classes.adornment}>
    <img src={images[type]} alt={alt} className={classes.logo} />
  </InputAdornment>
);

AdornmentInputs.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.oneOf(['caledar', 'cvv', 'number', 'typeDoc', 'user', 'card'])
    .isRequired,
  alt: PropTypes.string.isRequired,
};

export default withStyles(styles)(AdornmentInputs);
