import React from 'react'
import Contact from "../Contact/Contact";
import FormAsesoramiento from './FormAsesoramiento/FormAsesoramiento';
import './inicio.css'
import CelulasMadre from './CelulasMadre/CelulasMadre';
import BeneficiosCriopreservar from './BeneficiosCriopreservar/BeneficiosCriopreservar';
import {primerDiaTitle,primerDiaParrafo,primerDiaParrafoMobile, whatsapp} from './data'
import Servicos from './Servicios/Servicos';
import CyclicLine from './CyclicLine/CyclicLine';
import { WhatsappButton } from '../Whatsapp/WhatsappButton';
import Navbar from "../Navbar/Navbar";


const Inicio = () => {
    return (
        <div>
            {/* <Navigation/> */}
            <Navbar />
            <main className = "containerMain">
                <div className = "containerAsesoramiento">
                     <img src="img/new_portada.png" className="img-custom-mama" alt="Mamá" /> 
                     <div className='mobileTitlePrimerDia'>
                         {primerDiaTitle.map((title)=><h1 key = {title.key} className = "titlePrimerDiaMobile">{title.title}</h1> )}
                     </div>
                     <div className='containerPrimerDiaParrafoMobile'>
                        {primerDiaParrafoMobile.map((parrafo)=><p key= {parrafo.key} className = "paragraphPrimerDiaMobile" >{parrafo.text}</p>)}
                     </div>
                     <div className = "containerPrimerDia">
                        {primerDiaTitle.map((title)=><h1 key = {title.key} className = "titlePrimerDia">{title.title}</h1> )}
                        {primerDiaParrafo.map((primerDia)=><p key = {primerDia.key} className = "paragraphPrimerDia">{primerDia.text}</p>)}
                     </div>
                     <FormAsesoramiento/>
                </div>
                <div className = "containerRest">
                    <CelulasMadre/>
                    <CyclicLine/>
                    <BeneficiosCriopreservar/>
                    <Servicos/>
                    
                </div>
               
            </main>
            <Contact/>
            <WhatsappButton src={ whatsapp.src } />
            
        </div>
        
    )
}

export default Inicio
