import request from "request";
import {
    createUserInstance,
    getUserToken,
    checkTokenExpiration, getAMToken,
} from "./userManager";
import axios from "axios";
import { ENVIRONMENT_URL } from '../constants/index'

export function getBackendToken(url, values, successCallback, failCallback) {
  const { tDocumento, nDocumento, password, recaptchaResponse } = values;
  request(
    url,
    {
      method: "POST",
      json: {
        tipodoc: tDocumento,
        documento: nDocumento,
        password: password,
        recaptchaResponse: recaptchaResponse,
      },
    },
    (error, response, body) => {
      if (response && response.statusCode >= 200 && response.statusCode < 300) {
        if (typeof successCallback === "function") {
            const modelEspecificoUser = {
                docu_tipo: response.body.modelEspecificoUser.docu_tipo,
                docu_nro: response.body.modelEspecificoUser.docu_nro,
                nombre: response.body.modelEspecificoUser.nombre,
                apellido: response.body.modelEspecificoUser.apellido
            }

          createUserInstance(body, modelEspecificoUser);
          successCallback();
        }
      } else if (typeof failCallback === "function") {
        let msg = body ? body.message : "Servidor fuera de servicio";
        failCallback(msg);
      }
    }
  );
}

export function getInfo(path, successCallback, failCallback) {
  checkTokenExpiration(() => {
    request(
        `${ENVIRONMENT_URL}/app/api${path}`,
        // process.env.REACT_APP_BACKEND + "app/" + path,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAMToken(),
            "x-access-token": getUserToken(),
        },
      },
      (error, response, body) => {
        if (
          response &&
          response.statusCode >= 200 &&
          response.statusCode < 300
        ) {
          if (typeof successCallback === "function") {
            successCallback(JSON.parse(body));
          }
        } else if (typeof failCallback === "function") {
          failCallback(response, error);
        }
      }
    );
  });
}

export function postInfo(path, payload, successCallback, failCallback) {
  checkTokenExpiration(() => {
    request(
       `${ENVIRONMENT_URL}/app/api${path}`,
        // `${process.env.REACT_APP_BACKEND}/api${path}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAMToken(),
            "x-access-token": getUserToken(),
        },
        json: payload,
      },
      (error, response, body) => {
        if (
          response &&
          response.statusCode >= 200 &&
          response.statusCode < 300
        ) {
          if (typeof successCallback === "function") {
            successCallback(body);
          }
        } else if (typeof failCallback === "function") {
          let msg = body ? body.message : "Servidor fuera de servicio";
          failCallback(msg);
        }
      }
    );
  });
}

export async function validateUser(path, payload) {
    let rsp;
    await Promise.all([
        await axios.post(
             `${ENVIRONMENT_URL}/app/api${path}`,
            // `${process.env.REACT_APP_BACKEND}/api${path}`,
            { payload })
            .then((response) => {
                rsp = response;
            })
            .catch((error) => {
                rsp = {
                    data:{
                        status: 503,
                        message:'El servicio de validación previa de usuario registrado no se encuentra operativo. Le rogamos reintentar a la brevedad.'
                    }
                };
            })
    ]);
    return rsp;
}

export async function generatePin(path, payload) {
    let rsp;
    await Promise.all([
        await axios.post(
            `${ENVIRONMENT_URL}/app/api${path}`,
            // `${process.env.REACT_APP_BACKEND}/api${path}`,
            { payload })
            .then((response) => {
                rsp = response;
            })
            .catch((error) => {
                rsp = {
                    data:{
                        status: 503,
                        message:'El servicio de generación de PIN no se encuentra operativo. Le rogamos reintentar a la brevedad.'
                    }
                };
            })
    ]);
    return rsp;
}

export async function validationPin(path, payload) {
    let rsp;
    await Promise.all([
        await axios.post(
             `${ENVIRONMENT_URL}/app/api${path}`,
            // `${process.env.REACT_APP_BACKEND}/api${path}`,
            { payload })
            .then((response) => {
                rsp = response;
            })
            .catch((error) => {
                rsp = {
                    data:{
                        status: 503,
                        message:'El servicio de validación de PIN no se encuentra operativo. Le rogamos reintentar a la brevedad.'
                    }
                };
            })
    ]);
    return rsp;
}

export async function registerUser(path, payload) {
    let rsp;
    await Promise.all([
        await axios.post(
             `${ENVIRONMENT_URL}/app/api${path}`,
            // `${process.env.REACT_APP_BACKEND}/api${path}`,
            { payload })
            .then((response) => {
                rsp = response;
            })
            .catch((error) => {
                rsp = {
                    data:{
                        status: 503,
                        message:'El servicio de registración de usuarios no se encuentra operativo. Le rogamos reintentar a la brevedad.'
                    }
                };
            })
    ]);
    return rsp;
}
