import React from "react";
import { Typography, Container, CssBaseline, Grid } from "@material-ui/core";
import { useStyles } from "./styles";

function FormInfo() {
  const classes = useStyles();

  setTimeout(function () {
    window.location.href = "/login-clientes";
  }, 3000);

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid
            container
            className={classes.grid}
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item container justify="center">
              <Grid item container alignItems="center" direction="column">
                <Grid item>
                  <Typography component="h1" variant="h4">
                    Gracias por registrarte!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6">
                Serás redireccionado al inicio de la web...
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default FormInfo;
