import { useDispatch, useSelector } from "react-redux";
import {
  TERMS_CONDITIONS,
  TERMS_CONDITIONS_SUCCESS,
  TERMS_CONDITIONS_ERROR,
  TERMS_CONDITIONS_SUCCESS_FALSE,
  TERMS_CONDITIONS_SUCCESS_TRUE,
} from "../types";
import { messageAlert } from "../utils/alerts/alerts";
import { postInfo } from "../utils/backendRequest";
import {ENTIDAD, CANAL} from '../constants/index';

export const termsAndConditionsCells = (recaptcha) => {
  return new Promise((resolve, reject) => {
    postInfo(
      "/viewTermsConditions",
      {
        recaptchaResponse: recaptcha.recaptchaResponse
      },
      (body) => {
        resolve({
          texto: body.message,
        });
      },
      (err) => {
        reject(err);
      }
    );
  });
};

const termsAndConditions = () => {
  return new Promise((resolve, reject) => {
    postInfo(
      "/termsConditions",
      {
        entidad: ENTIDAD,
        canal: CANAL,
      },
      (body) => {
        resolve({
          texto: body.texto,
        });
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const verifyTermsAndConditions = (idUser, canal = 1, entidad = 9) => {
  return new Promise((resolve, reject) => {
    postInfo(
      "/verificationTerms",
      {
        idUser,
        canal,
        entidad,
      },
      (body) => {
        const response = {
          terminos: body.terminos,
          estado: body !== undefined ? body.estado : "",
        };
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export function verifyTermsConditionsAction(id_user){
  return async (dispatch) => {
    const stateTerms = await verifyTermsAndConditions(id_user);
    const responseTermsAndConditions = await termsAndConditions();
    const response = {
        terminos: stateTerms.terminos,
        texto: responseTermsAndConditions.texto
    }
    window.sessionStorage.setItem("stateOfTermsConditions", stateTerms.estado);
    if (!stateTerms) {
      dispatch(termsConditionsSuccessFalse(response));
    } else {
      dispatch(termsConditionsSuccessTrue(response));
    }
  };
}

export function acceptTermsConditionsAction(idTerminos, idUser) {
  return ()=> { postInfo("/acceptTerms",
  {
    canal: CANAL,
    idUser: idUser,
    idTerminos: idTerminos,
    estado: ENTIDAD,
    extras: `${window.navigator.userAgent}`,
    ip: `${window.CLIENT_IP}`,
    usuario: 'WEB'
  }, (body)=> {
      if (body.status == "OK") {
        messageAlert("success", "Aviso", `${body.message}`);
      } else {
        messageAlert(
          "info",
          "Confirmación",
          "Hubo un inconveniente en la aceptación de términos y condiciones. Le rogamos reintente cuando ingrese nuevamente. ¡Muchas gracias!"
        );
      }
    },()=>{})
  }
}

const termsConditions = () => ({
  type: TERMS_CONDITIONS,
  payload: false,
});

const termsConditionsSuccessFalse = (data) => ({
  type: TERMS_CONDITIONS_SUCCESS_FALSE,
  payload: data,
});

const termsConditionsSuccessTrue = (data) => ({
  type: TERMS_CONDITIONS_SUCCESS_TRUE,
  payload: data,
});

const termsConditionsError = (error) => ({
  type: TERMS_CONDITIONS_ERROR,
  payload: error,
});
