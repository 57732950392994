
import React from 'react';
import { Link } from 'react-router-dom';
import './lineaDenuncuasComponente.css'

const LineaDenunciasComponent = ({ data }) => {
    return (
        <>
            <h1 className="parrafo titulo" style={{marginTop : '83px'}}>
                { data.lineaDenuncias.titulo }
            </h1>
            <p>
                { data.lineaDenuncias.parrafo1 }
            </p>
            <p className="parrafo">
                { data.lineaDenuncias.parrafo2 }
            </p>  
            <div className='containerListDenuncia'>
            <p>
                <i className={`${ data.lineaDenuncias.contacto.telefono.icono }`}>
                    <p className="margen"> { data.lineaDenuncias.contacto.telefono.numero } </p> 
                </i>
            </p>    
            <p>
                <i className={`${ data.lineaDenuncias.contacto.correo.icono }`} >
                    <p className="margen"> { data.lineaDenuncias.contacto.correo.mail } </p>
                </i>
            </p>
            <p className="parrafo">
                <i className={`${ data.lineaDenuncias.adjunto.icono }`}>
                    <a href={`${ data.lineaDenuncias.adjunto.path }`} target="_blank">
                        <p className="margen color-font-item"> { data.lineaDenuncias.adjunto.titulo }  </p>    
                    </a> 
                </i>
            </p>
            </div>
            <p>
                { data.lineaDenuncias.nota_al_pie.texto1 }
            </p>
            <p className="gestion-denuncias">
                <b>{ data.lineaDenuncias.nota_al_pie.texto2 } </b>
            </p>
        </>
    )
}

export default LineaDenunciasComponent;



