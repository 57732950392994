
import React from 'react';
import data from '../../data/lineaDenuncias.json';
import LineaDenunciasComponent from './componentes/LineaDenunciasComponent';
import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";
import './lineaDenuncias.css';
import { WhatsappButton } from '../Whatsapp/WhatsappButton';
import ScrollToTopOnMount from '../../utils/scrollHeader';

export const lineaDenuncias = () => {
    return (
      <>
        <Navbar />
        <ScrollToTopOnMount />
        <div className="container contenedor">
          <div className="row">
            <div className="col-md-10">

              <LineaDenunciasComponent data={ data }/>

            </div>
          </div>
          
        </div>  
        <WhatsappButton src= { data.lineaDenuncias.whatsapp.src } />
        <Contact />
      </>  
    )
}


