import { AppBar, Toolbar, Grid, MenuItem, Menu, Tab, IconButton } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      "& .MuiTab-root": {
        minWidth: "110px",
        opacity: 1,
      },
      "& .MuiAppBar-root": {
        backgroundColor: "#ecf4f5 !important",
        position: "inherit",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "2.5rem",
      },
      "& .MuiPaper-root": {
        backgroundColor: "#ecf4f5",
      },
      "& .MuiAppBar-colorPrimary":{
        backgroundColor: "red"
      },
      "& .MuiIconButton-root": {
        fontSize: "9px",
      },
      "& .MuiButton-root": {
        fontFamily: 'Red Hat Display',
        size: "40px",
        fontSize: "14px",
        marginLeft: "38px",
      },
      "& .navMenu button": {
        lineHeight: "15px"
      },
      backgroundColor: "#ecf4f5",
      boxShadow: "none",
      color: "#00748b",
    },
    sectionMobile: {
      width:"100%",
      display: "flex",
      justifyContent: "flex-end"
    },
    textMenu:{
      fontSize:"14px",
    }
}));


const NavbarMobile = ({isLogged}) => {
    const {
        sectionMobile,
        textMenu,
        root
    } = useStyles();
    
    const menuId = "primary-search-account-menu";
    const mobileMenuId = "primary-search-account-menu-mobile";
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  
    const isMenuOpen = Boolean(anchorEl);
  
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
      };
    
      const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
      };
    
    return (
        <>
            <AppBar position="fixed" className={root}>
                <Toolbar>
                  <div>
                      <div>
                        <a className="navbar-logo img-cells page-scroll" href="/">
                            <img src="img/logo-cells-new.png" alt="" />
                        </a>{" "}
                      </div>
                  </div>
                  <div className={sectionMobile}>
                      {/*<IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                      >
                        <AccountCircle />
                      </IconButton>*/}
                      <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                  </div>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}>
                <MenuItem className={root}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Tab href="banco-celulas-madre" label="CÉLULAS MADRE" className={textMenu}/>
                        <Tab href="servicios-smg-cells" label="SERVICIOS" className={textMenu}/>
                        <Tab href="preguntas-frecuentes-celulas-madre" label="PREGUNTAS FRECUENTES" className={textMenu}/>
                        <Tab href="por-que-elegirnos" label="POR QUÉ ELEGIRNOS" className={textMenu}/>
                        {!isLogged ? <Tab href="registrar-nuevo-clientes" label="REGISTRARSE" className={textMenu}/> : ""}
                        {!isLogged ? <Tab href="login-clientes" label="INGRESAR" className={textMenu}/> : ""}
                        {isLogged ? <Tab href="/" label="CERRAR SESION" className={textMenu}/> : ""}
                    </Grid>
                </MenuItem>
            </Menu>
        </>
    )
}

export default NavbarMobile;