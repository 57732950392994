import React from 'react'
import { FormasDePagoComponent } from './componentes/FormasDePagoComponent';
import data from '../../data/formasDePago.json';
import Contact from '../Contact/Contact';
import Navbar from '../Navbar/Navbar';
import './formasDePago.css';
import { WhatsappButton } from '../Whatsapp/WhatsappButton';
import ScrollToTopOnMount from '../../utils/scrollHeader';

export const formasDePago = () => {
    return (
        <>
        <Navbar />
        <ScrollToTopOnMount />
        <div className="container contenedor">
          <div className="row">
            <div className="col-md-10">

              <FormasDePagoComponent data={ data }/>

            </div>
          </div>
          
        </div>  
        <WhatsappButton src= { data.formasDePago.whatsapp.src } />
        <Contact />
      </>  
    )
}
