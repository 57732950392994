import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import {
  Typography,
  Grid,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails, Link,
} from "@material-ui/core";

import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Switch,
  Box,
  Divider,
  Button,
} from "@material-ui/core";

import { InputField, CheckboxField } from "../../FormFields";
import { useFormikContext } from "formik";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { termsAndConditionsCells } from "../../../../actions/termsConditionsActions";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import {SITE_KEY} from "../../../../constants";

function Cells(props) {
  const {
    formField: { contrato, terms },
  } = props;

  const { values: formValues } = useFormikContext();

  const classes = useStyles();
  const recaptchaRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");


  const handleOpen = (e) => {
      if(e.target.checked === true){
          Swal.fire({
              title: "Terminos y condiciones Cells",
              html: `<div style="max-height: 50vh;">${text}</div>`,
              icon: "info",
              confirmButtonText: "Aceptar",
              width: "550px",
              allowOutsideClick: true,
              allowEscapeKey: true,
              customClass: {
                  container: "max-height: 50vh;",
              },
          }).then(
              handleClose()
          )
      }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [expandedPanel, setExpandedPanel] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
    setChecked(isExpanded ? true : false);
  };


  useEffect(() => {
    async function fetchData() {
        const tokenRc = await recaptchaRef.current.executeAsync(); //grecaptcha.execute();
        recaptchaRef.current?.reset();
        if(tokenRc){
            const data = {
                recaptchaResponse: tokenRc
            }
            const response = await termsAndConditionsCells(data);
            if(response){
                setText(response.texto);
            }else{
                setText('Se produjo un inconveniente momentáneo en la recuperación de las términos y condiciones. Puede reintentar a la brevedad.');
            }

        }

    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography style={{ color: "#00748b" }} variant="h4" gutterBottom>
            Vinculá tu producto
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" gutterBottom>
            Seleccioná el producto que querés vincular.
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          xs={12}
          sm={6}
          spacing={2}
          className={classes.color}
          style={{
            borderRadius: "10px 10px 10px 10px",
            marginBottom: "50px",
            marginTop: "50px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <Grid item container direction="column" xs={12} sm={10}>
            <div className="App">
              <Accordion
                expanded={
                  expandedPanel ===
                  (formValues.contrato !== "" && !checked ? false : "panel1")
                }
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  classes={{
                    expandIcon: classes.expandIcon,
                    expanded: classes.expanded,
                    content: classes.content,
                  }}
                >
                  <Typography>SMG Cells</Typography>
                  <Switch
                    classes={{
                      track: classes.switch_track,
                      switchBase: classes.switch_base,
                      colorPrimary: classes.switch_primary,
                    }}
                    defaultChecked={checked}
                    checked={
                      formValues.contrato === "" && !checked ? false : true
                    }
                    onChange={handleAccordionChange("panel1")}
                  />
                </AccordionSummary>
                <Divider
                  style={{
                    alignSelf: "auto",
                    marginLeft: "17px",
                    width: "93%",
                  }}
                />
                <AccordionDetails>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignContent="start"
                      alignItems="start"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Grid item xs={8} align="left">
                          <InputField
                            name={contrato.name}
                            label={contrato.label}
                            fullWidth
                          />
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
        <CheckboxField
          onChange={handleOpen}
          style={{ color: "#00748b" }}
          name={terms.name}
          label={terms.label}
          value={formValues.terms}
        />
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box
                mb={2}
                display="flex"
                flexDirection="column"
                className={classes.container_with_scrolls}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  //width: 600,
                  height: 400,
                  bgcolor: "background.paper",
                  borderRadius: "10px",
                  boxShadow: 24,
                  py: 4,
                  paddingLeft: 20,
                  paddingRight: 10,
                  overflow: "hidden",
                  overflowY: "scroll",
                  textAlign: "justify",
                }}
              >
                <Typography variant="h6" component="h2" sx={{ px: 4 }}>
                  Lorem ipsum
                </Typography>
                <Typography
                  component="div"
                  sx={{ px: 4, mt: 2, maxHeight: "50vh", overflowY: "auto" }}
                >
                    {text}
                </Typography>
                <br />
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                >
                  Cerrar
                </Button>
              </Box>
            </Fade>
          </Modal>

        </div>
      </Grid>
        <ReCAPTCHA
            ref={recaptchaRef}
            style={{ marginTop: "5%" }}
            size="invisible"
            sitekey={SITE_KEY}
        />
    </React.Fragment>
  );
}

export default Cells;
