import {useState} from 'react'
import './serviciosDesktop.css'
import { Grid } from '@material-ui/core';

const ServiciosDesktop = ({serviceOne, serviceTwo, serviceThree, plans}) => {
  
    return (
        <>
            <Grid container spacing={1} justifyContent="center" sm={12}>
                <Grid item xs={6}>
                    <Grid spacing={2} style={ {backgroundColor:"white", borderRadius:"10px"} }>
                        <div className='serviceHead descHead' >
                            <div className='descFlowers'></div>
                        </div>
                        <div className='serviceBody descBody'>
                            <ul>
                                {plans.map((plan)=> <li id={plan.id}>{plan.text}</li>)}
                            </ul>
                        </div>
                        <div className='serviceFooter' style={{backgroundColor: '#fff'}}></div>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid spacing={2} style={ {backgroundColor:"white", borderRadius:"10px"} }>
                        <div className='serviceTitle' style={{backgroundColor: serviceOne.color, color: '#fff'}}>
                            <h2>
                                {serviceOne.title}
                            </h2>
                        </div>
                        <div className='serviceBody descBodyPlanes'>
                            <ul>
                                {serviceOne.plan.map((plan)=> <li id={plan.id}> <img className =  {` iconDetallePlan showPlan` }  src={plan.icon} alt = "icono"/> </li>)}
                            </ul>
                        </div>
                        <div className='serviceFooter' style={{backgroundColor: serviceOne.color}}></div>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid spacing={2} style={ {backgroundColor:"white", borderRadius:"10px"} }>
                        <div className='serviceTitle' style={{backgroundColor: serviceTwo.color, color: '#fff'}}>
                            <h2 className='cyclicTitle'>
                                {serviceTwo.title.split(" ", 2).join(" ")}
                            </h2> 
                        </div>
                        <div className='serviceBody descBodyPlanes'>
                            <ul>
                                {serviceTwo.plan.map((plan)=> <li id={plan.id}> <img className =  {` iconDetallePlan showPlan` }  src={plan.icon} alt = "icono"/> </li>)}
                            </ul>
                        </div>
                        <div className='serviceFooter' style={{backgroundColor: serviceTwo.color}}></div>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid spacing={2} style={ {backgroundColor:"white", borderRadius:"10px"} }>
                    <div className='serviceTitle' style={{backgroundColor: serviceThree.color, color: '#fff'}}>
                        <h2 className='hermanosTitle'>
                            {serviceThree.title.split(" ", 2).join(" ")}
                        </h2>
                        <span className='hermanosOffert'>
                            50%OFF**
                        </span>
                    </div>
                        <div className='serviceBody descBodyPlanes'>
                            <ul>
                                {serviceThree.plan.map((plan)=> <li id={plan.id}> <img className =  {` iconDetallePlan showPlan` }  src={plan.icon} alt = "icono"/> </li>)}
                            </ul>
                        </div>
                        <div className='serviceFooter' style={{backgroundColor: serviceThree.color}}></div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ServiciosDesktop
